import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'dir-dev-plan',
    templateUrl: './dev-plan.component.html',
    styleUrls: ['./dev-plan.component.scss']
})
export class DevPlanComponent implements OnInit {
    tasks: DevTask[] = [
        {
            id: 1,
            title: 'Edit offices and departments',
            description: 'Create a new feature',
            isDone: true
        },
        {
            id: 72,
            title: 'Sorting users',
            description: 'Create a new feature',
            isDone: true,
            isV2: false
        },
        {
            id: 74,
            title: 'Grouping users',
            description: 'Create a new feature',
            isDone: true,
            isV2: false
        },
        {
            id: 73,
            title: 'Update GOOD theme to the latest version (this is tricky)',
            description: 'Create a new feature',
            isDone: true
        },
        {
            id: 2,
            title: 'Decide about cities and job titles',
            description: 'Create a new feature',
            isDone: true
        },
        {
            id: 53,
            title: 'Table view for everything',
            description: 'Create a new feature',
            isDone: true
        },
        {
            id: 54,
            title: 'Simplify directory component, use only one dir-users component instance',
            description: 'Create a new feature',
            isDone: true
        },
        {
            id: 3,
            title: 'Page toolbar, so far the only useful thing is the refresh button',
            description: 'Create a new feature',
            isDone: true
        },
        {
            id: 4,
            title: 'Navigate left-right on person info dialog',
            description: 'Create a new feature',
            isDone: true
        },
        {
            id: 5,
            title: 'Show manager/reports in person info dialog',
            description: 'Create a new feature',
            isDone: false
        },
        {
            id: 6,
            title: 'Cache pictures/absense of a picture',
            description: 'Create a new feature',
            isDone: true
        },
        {
            id: 7,
            title: 'Pictures/logos for departments',
            description: 'Create a new feature',
            isDone: true
        },
        {
            id: 8,
            title: 'Welcome page',
            description: 'Create a new feature',
            isDone: false
        },
        {
            id: 9,
            title: 'Sign in / sign up page',
            description: 'Create a new feature',
            isDone: false
        },
        {
            id: 10,
            title: 'Settings page, what kind of settings?',
            description: 'Create a new feature',
            isDone: true
        },
        {
            id: 11,
            title: 'About/support/privacy',
            description: 'Create a new feature',
            isDone: false
        },
        {
            id: 62,
            title: 'Subscription management',
            description: 'Create a new feature',
            isDone: true
        },
        {
            id: 63,
            title: 'Trial management',
            description: 'Create a new feature',
            isDone: true
        },
        {
            id: 64,
            title: 'Onboarding, tutorial, etc.',
            description: 'Create a new feature',
            isDone: false
        },
        {
            id: 65,
            title: 'Sign out button',
            description: 'Create a new feature',
            isDone: true
        },
        {
            id: 66,
            title: 'Teams APP!!!',
            description: 'Create a new feature',
            isDone: true
        },
        {
            id: 67,
            title: 'Admin/regular users',
            description: 'Create a new feature',
            isDone: true
        },
        {
            id: 68,
            title: 'Rules/Filters',
            description: 'Create a new feature',
            isDone: true
        },
        {
            id: 69,
            title: 'Security groups',
            description: 'Create a new feature',
            isDone: false,
            isV2: true
        },
        {
            id: 12,
            title: 'Localization?',
            description: 'Create a new feature',
            isDone: false
        },
        {
            id: 13,
            title: 'People cards animation is nice, add more subtle animations?',
            description: 'Create a new feature',
            isDone: false
        },
        {
            id: 14,
            title: 'Server: which database?',
            description: 'Create a new feature',
            isDone: true
        },
        {
            id: 15,
            title: 'Server: db model, entity framework',
            description: 'Create a new feature',
            isDone: true
        },
        {
            id: 16,
            title: 'Server: API authentication',
            description: 'Create a new feature',
            isDone: true
        },
        {
            id: 17,
            title: 'Server: offices/departments/cities API',
            description: 'Create a new feature',
            isDone: true
        },
        {
            id: 69,
            title: 'More data sources',
            description: 'Create a new feature',
            isDone: false,
            isV2: true
        },
        {
            id: 70,
            title: '"No items found" message',
            description: 'Create a new feature',
            isDone: false,
            isV2: false
        }
    ];
    constructor() {}

    ngOnInit(): void {}
}

interface DevTask {
    id: number;
    title: string;
    description: string;
    isDone: boolean;
    isV2?: boolean;
}
