import { INGXLoggerConfig, INGXLoggerLogPosition, INGXLoggerMetadata, NGXLoggerMapperService, NGXLoggerWriterService } from 'ngx-logger';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export class LoggerMapperProvider extends NGXLoggerMapperService {
    public getSourceMap(
        sourceMapLocation: string,
        distPosition: INGXLoggerLogPosition
    ): Observable<INGXLoggerLogPosition> {
        console.log('LoggerMapperProvider.getSourceMap', sourceMapLocation, distPosition);
        return new Observable<INGXLoggerLogPosition>((observer) => {
            observer.next(distPosition);
            observer.complete();
        });
    }
}

export class LoggerWriterService extends NGXLoggerWriterService {
    protected getFileDetailsToWrite(metadata, config) {
        if (config.disableFileDetails === true) {
            return '';
        } else {
            //const fileName = metadata.fileName.split('/').pop();
            return `[${metadata.fileName}:${metadata.lineNumber}]`;
        }
    }
    protected prepareMetaString(metadata: INGXLoggerMetadata, config: INGXLoggerConfig): string {
        const fileName =
            metadata.fileName?.charAt(0) === '.' ? 'webpack://' + metadata.fileName?.substring(1) : environment.serverUrl + '/' + metadata.fileName;
        return super.prepareMetaString({ ...metadata, fileName }, config);
    }
}
