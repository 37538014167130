import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as AuthActions from './auth.actions';
import * as fromRoot from '../reducers';
import { MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'dir-teams-auth',
    templateUrl: './teams-auth.component.html',
    styleUrls: ['./teams-auth.component.scss']
})
export class TeamsAuthComponent implements OnInit {
    loginHint = '';

    constructor(private store: Store, private authService: MsalService) {
        this.store.pipe(select(fromRoot.selectQueryParam('loginHint'))).subscribe((loginHint) => {
            this.loginHint = loginHint;
        });
    }

    ngOnInit(): void {
        console.log('TeamsAuthComponent ngOnInit');
        this.authService.initialize().subscribe(() => {
            if (environment.debugDelays) {
                window.setTimeout(() => this.sendLoginAction(), 5000);
            } else {
                this.sendLoginAction();
            }
        });
    }

    sendLoginAction() {
        console.log('TeamsAuthComponent authService.initialize');
        this.store.dispatch(AuthActions.msalLogin({ loginHint: this.loginHint }));
    }
}
