import { STROKE_COLOR, STROKE_OPACITY } from './constants';
import { fillOpacity, fillColor } from './helpers';

export function buildHexagonShape(sideLength: number) {
    const c = sideLength;
    const a = c / 2;
    const b = Math.sin((60 * Math.PI) / 180) * c;
    return [0, b, a, 0, a + c, 0, 2 * c, b, a + c, 2 * b, a, 2 * b, 0, b].join(',');
}

export function buildChevronShape(width, height) {
    var e = height * 0.66;
    return [
        [0, 0, width / 2, height - e, width / 2, height, 0, e, 0, 0],
        [width / 2, height - e, width, 0, width, e, width / 2, height, width / 2, height - e]
    ].map(function (x) {
        return x.join(',');
    });
}

export function buildPlusShape(squareSize) {
    return [
        [squareSize, 0, squareSize, squareSize * 3],
        [0, squareSize, squareSize * 3, squareSize]
    ];
}

export function buildOctogonShape(squareSize) {
    var s = squareSize;
    var c = s * 0.33;
    return [c, 0, s - c, 0, s, c, s, s - c, s - c, s, c, s, 0, s - c, 0, c, c, 0].join(',');
}

export function buildTriangleShape(sideLength, height) {
    var halfWidth = sideLength / 2;
    return [halfWidth, 0, sideLength, height, 0, height, halfWidth, 0].join(',');
}

export function buildDiamondShape(width, height) {
    return [width / 2, 0, width, height / 2, width / 2, height, 0, height / 2].join(',');
}

export function buildRightTriangleShape(sideLength) {
    return [0, 0, sideLength, sideLength, 0, sideLength, 0, 0].join(',');
}

export function drawInnerMosaicTile(svg, x, y, triangleSize, vals) {
    var triangle = buildRightTriangleShape(triangleSize);
    var opacity = fillOpacity(vals[0]);
    var fill = fillColor(vals[0]);
    var styles = {
        stroke: STROKE_COLOR,
        'stroke-opacity': STROKE_OPACITY,
        'fill-opacity': opacity,
        fill: fill
    };

    svg.polyline(triangle, styles).transform({
        translate: [x + triangleSize, y],
        scale: [-1, 1]
    });
    svg.polyline(triangle, styles).transform({
        translate: [x + triangleSize, y + triangleSize * 2],
        scale: [1, -1]
    });

    opacity = fillOpacity(vals[1]);
    fill = fillColor(vals[1]);
    styles = {
        stroke: STROKE_COLOR,
        'stroke-opacity': STROKE_OPACITY,
        'fill-opacity': opacity,
        fill: fill
    };

    svg.polyline(triangle, styles).transform({
        translate: [x + triangleSize, y + triangleSize * 2],
        scale: [-1, -1]
    });
    svg.polyline(triangle, styles).transform({
        translate: [x + triangleSize, y],
        scale: [1, 1]
    });
}

export function drawOuterMosaicTile(svg, x, y, triangleSize, val) {
    var opacity = fillOpacity(val);
    var fill = fillColor(val);
    var triangle = buildRightTriangleShape(triangleSize);
    var styles = {
        stroke: STROKE_COLOR,
        'stroke-opacity': STROKE_OPACITY,
        'fill-opacity': opacity,
        fill: fill
    };

    svg.polyline(triangle, styles).transform({
        translate: [x, y + triangleSize],
        scale: [1, -1]
    });
    svg.polyline(triangle, styles).transform({
        translate: [x + triangleSize * 2, y + triangleSize],
        scale: [-1, -1]
    });
    svg.polyline(triangle, styles).transform({
        translate: [x, y + triangleSize],
        scale: [1, 1]
    });
    svg.polyline(triangle, styles).transform({
        translate: [x + triangleSize * 2, y + triangleSize],
        scale: [-1, 1]
    });
}

export function buildRotatedTriangleShape(sideLength: number, triangleWidth: number) {
    var halfHeight = sideLength / 2;
    return [0, 0, triangleWidth, halfHeight, 0, sideLength, 0, 0].join(',');
}
