<div *ngIf="mail && isValidEmail()" class="mt-2">
    <a href="mailto:{{mail}}" (click)="onComposeMail($event)" class="text-hover-primary">
        <i class="bi bi-envelope px-1" [ngClass]="{'fs-3': size === 'sm', 'fs-2tx me-3': size === 'md', 'fs-3hx me-2': size === 'lg'}"></i>
    </a>
    <a *ngIf="!isOutlook && isValidEmail()" href="#" (click)="onChat($event)" class="text-hover-primary">
        <i class="bi bi-chat-left-text ps-1" [ngClass]="{'fs-4': size === 'sm', 'fs-2qx me-3': size === 'md', 'fs-3x me-2': size === 'lg'}"></i>
    </a>
    <a *ngIf="isValidEmail()" href="#" (click)="onCall($event)" class="text-hover-primary">
        <i class="bi bi-telephone ps-1" [ngClass]="{'fs-4': size === 'sm', 'fs-2qx': size === 'md', 'fs-3x': size === 'lg'}"></i>
    </a>
</div>
