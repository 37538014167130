<div class="card card-flush" @usersEnterLeave>
    <div class="card-body pt-2" *ngIf="viewMode === usersViewMode.Cards">
        <div class="row">
            <div class="col-12 col-sm-6 col-md-4 col-xxxl-3 mb-2 user-box" *ngFor="let user of users"
                (click)="navigateToUser(user.id)">
                <div class="d-flex border border-dashed rounded bg-secondary flex-column inner-box">
                    <dir-user-presence *ngIf="presenceFeatureConsented" [id]="user.id"></dir-user-presence>
                    <div class="d-flex align-items-top px-3 py-2">
                        <div>
                            <dir-graph-picture [directoryUser]="user" [size]="50" classes="symbol-100px symbol-sm-50px"></dir-graph-picture>
                            <dir-chat [mail]="user.mail" class="d-none d-sm-block"></dir-chat>
                        </div>
                        <div class="ms-3 ms-sm-4 w-100 d-flex flex-column">
                            <div class="d-flex flex-row align-items-center">
                                <a [routerLink]="[]" [queryParams]="{ userId: user.id }"
                                    class="text-gray-800 text-hover-primary fs-5 fw-bolder">
                                    <dir-txt [text]="user[userColumnSettings[0]?.originalName]"></dir-txt>
                                </a>
                                <a [routerLink]="[]" class="text-hover-primary text-muted ms-1"
                                    (click)="toggleFavorite($event, user)">
                                    <i class="ki-star fs-3"
                                        [ngClass]="{'ki-duotone': isFavorite(user), 'ki-outline': !isFavorite(user)}"></i>
                                </a>
                            </div>
                            <div class="fw-bold fs-7" *ngIf="userColumnSettings[1]">
                                <dir-txt [text]="user[userColumnSettings[1]?.originalName]"></dir-txt>
                            </div>
                            <div class="text-muted fs-7" *ngIf="userColumnSettings[2]">
                                <dir-txt [text]="user[userColumnSettings[2]?.originalName]"></dir-txt>
                            </div>
                            <dir-chat [mail]="user.mail" class="d-block d-sm-none" style="margin-top: auto;" size="lg"></dir-chat>
                        </div>
                        <div>
                            <input type="checkbox" class="d-flex align-items-top px-3 pb-2 inner-box"
                                class="form-check-input float-end small-checkbox" (click)="onSelected($event,user)"
                                [checked]="isChecked(user)" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="viewMode === usersViewMode.Faces" class="card-body pt-2">
        <div class="row">
            <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xxl-3 col-xxxl-2 mt-2 mb-4 user-box"
                *ngFor="let user of users">
                <div class="me-72 mb-2 d-flex justify-content-center" (click)="navigateToUser(user.id)">
                    <div class="symbol symbol-250px symbol-sm-200px symbol-md-150px symbol-fixed position-relative">
                        <dir-graph-picture [directoryUser]="user" [size]="200"
                            [classes]="'symbol-250px symbol-sm-200px symbol-md-150px'">
                        </dir-graph-picture>
                        <dir-user-presence mode="circle" *ngIf="presenceFeatureConsented"
                            [id]="user.id"></dir-user-presence>
                    </div>
                </div>
                <div class="d-flex justify-content-center flex-column align-items-center text-center">
                    <div class="fw-bolder fs-5">
                        <a [routerLink]="[]" [queryParams]="{ userId: user.id }"
                            class="text-gray-800 text-hover-primary fs-5 fw-bolder"><dir-txt
                                [text]="user[userColumnSettings[0]?.originalName]"></dir-txt>
                        </a>
                    </div>
                    <div class="fs-6">
                        <dir-txt [text]="user[userColumnSettings[1]?.originalName]"></dir-txt>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
