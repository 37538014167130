import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'dir-toc-welcome',
    templateUrl: './toc-welcome.component.html',
    styleUrl: './toc-welcome.component.scss'
})
export class TocWelcomeComponent {
    constructor(private router: Router) { }
    goDirectory() {
        this.router.navigate(['/directory']);
    }

    closeWindow() {
        window.close();
    }
}
