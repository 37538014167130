import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import * as fromRoot from '../reducers';
import { uiFeature } from '../ui/ui.reducer';
import { Subject, takeUntil } from 'rxjs';
@Component({
    selector: 'dir-pricing-page',
    templateUrl: './pricing-page.component.html',
    styleUrls: ['./pricing-page.component.scss']
})
export class PricingPageComponent implements OnInit, OnDestroy {

    private readonly unsubscribe$ = new Subject<void>();

    appVersion = environment.appVersion;
    hostedInTOC: boolean = false;
    constructor(private store: Store) {}

    ngOnInit(): void {
        this.store
            .select(uiFeature.selectHostedInTOC)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((hostedInTOC) => {
                this.hostedInTOC = hostedInTOC;
            });

    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
