<div *ngIf="showValue()" class="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
    <div class="fw-bold text-gray-400 d-flex justify-content-center">{{columnDefinition.name}}</div>

    <ng-container *ngIf="columnDefinition.type !== columnType.ItemCount">
        <div markdown class="d-flex justify-content-center" [data]="getItemValue()" [inline]="true">
        </div>
    </ng-container>

    <ng-container *ngIf="columnDefinition.type === columnType.ItemCount">
        <div class="d-flex justify-content-center">{{categoryItem.people.length}}</div>
    </ng-container>


    <ng-container *ngIf="columnDefinition.type === columnType.ManagerCount">
        <div class="d-flex justify-content-center">{{getManagerCount()}}</div>
    </ng-container>
</div>
