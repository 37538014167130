import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Category, UserColumnSetting } from 'src/app/data/categories';
import { DirectoryUser } from 'src/app/data/data.models';
import * as dataActions from '../../data/data.actions';

@Component({
    selector: 'dir-details-field',
    templateUrl: './details-field.component.html',
    styleUrls: ['./details-field.component.scss']
})
export class DetailsFieldComponent {
    @Input() user: DirectoryUser;
    @Input() userColumnSetting: UserColumnSetting;
    @Input() categories: Category[] = [];
    @Input() hyperLinkCategories: boolean = true;


    constructor(private store: Store) {

    }

    closeModal() {       
        this.store.dispatch(dataActions.dialogHide());
    }

    isCategory(): boolean {
        const c = this.categories.find((x) => x.fieldName === this.userColumnSetting.originalName);
        return c != null;
    }

    getCategoryName(): string {
        return this.categories.find((x) => x.fieldName === this.userColumnSetting.originalName).slug;
    }

    getCategoryIcon(): string {
        const c = this.categories.find((x) => x.fieldName === this.userColumnSetting.originalName);
        if (c) {
            return `bi-${c.icon}`;
        } else {
            if (this.userColumnSetting) {
                if (this.userColumnSetting.originalName === 'mail') {
                    return 'bi-envelope';
                }
                if (this.userColumnSetting.originalName === 'mobilePhone') {
                    return 'bi-phone';
                }
            }
            return '';
        }
    }
}
