import { DefaultPictureSet, DefaultPicturesForCategory } from './categories';
import cities from './cities.json';

export const defaultCityPictures: DefaultPictureSet = {
    'new york': 'assets/img/cities/ny.jpeg',
    kyiv: 'assets/img/cities/kyiv.jpeg',
    cairo: 'assets/img/cities/cairo.jpeg',
    london: 'assets/img/cities/london.jpeg',
    sydney: 'assets/img/cities/sydney.jpeg',
    luanda: 'assets/img/cities/luanda.jpeg',
    lviv: 'assets/img/cities/luanda.jpeg'
};

export interface CityItem {
    name: string;
    type: string;
    city?: string;
    original?: string;
    //variants?: { variant: '600', '2400', 'cropped', 'original' }[];
    variants?: { variant: string; path: string; width: number }[];
    contents?: CityItem[];
}

export const CityPicturesUrlPrefix = 'storage_city_images://';

function flattenCityItems(items: CityItem[], acc: CityItem[] = []): CityItem[] {
    for (const item of items) {
        if (item.type === 'file') {
            acc.push(item);
        } else if (item.type === 'folder' && item.contents) {
            flattenCityItems(item.contents, acc);
        }
    }
    return acc;
}

const flatCityItems = flattenCityItems(cities.contents);
function groupCityItemsByCity(items: CityItem[], acc: Record<string, CityItem[]> = {}): Record<string, CityItem[]> {
    for (const item of items) {
        if (item.type === 'file') {
            const city = item.city?.toLowerCase() || 'unknown';
            if (!acc[city]) {
                acc[city] = [];
            }
            acc[city].push(item);
        } else if (item.type === 'folder' && item.contents) {
            groupCityItemsByCity(item.contents, acc);
        }
    }
    return acc;
}

const groupedCityItems = groupCityItemsByCity(flatCityItems);

export function getRandomCityItem(city: string): CityItem | undefined {
    const cityItems = groupedCityItems[city.toLowerCase()];
    if (cityItems && cityItems.length > 0) {
        const randomIndex = Math.floor(Math.random() * cityItems.length);
        return cityItems[randomIndex];
    }
    return undefined;
}

export function getRandomCityItemUrl(city: string): string | undefined {
    const item = getRandomCityItem(city);
    return item ? `${CityPicturesUrlPrefix}${item.name}` : undefined;
}

export function getPictureVariantPath(name: string, size: string) {
    //find city by name
    const item = flatCityItems.find((i) => `${CityPicturesUrlPrefix}${i.name}` === name);
    //find variant by size
    let variant = '600';
    switch (size) {
        case 'S':
            variant = '600';
            break;
        case 'B':
            variant = '2400';
            break;
        case 'L':
            variant = 'cropped';
            break;
    }

    if (!item) {
        return null;
    }
    const variants = item.variants || [];
    const variantItem = variants.find((v) => v.variant === variant);
    return variantItem ? `https://storagecityimages.blob.core.windows.net/images/${variantItem.path}` : null;
}

export const defaultPicturesForCategory: DefaultPicturesForCategory = {
    cities: defaultCityPictures
};

export const citiesData = cities as CityItem;
