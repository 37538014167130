import { CategoryItem } from '../data/categories';

export class FieldHelper {
    public static getManagerCount(categoryItem: CategoryItem): number {
        if (!categoryItem.people) {
            return 0;
        }

        let managerCount = 0;

        categoryItem.people.forEach((p) => {
            categoryItem.people.filter((p2) => p2.id === p.managerId).length > 0 ? managerCount++ : null;
        });

        return managerCount;
    }
}
