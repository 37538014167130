import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Category } from 'src/app/data/categories';
import { CategoryItemsViewMode, SortOrder } from '../../data/data.models';

@Component({
    selector: 'dir-category-toolbar',
    templateUrl: './category-toolbar.component.html',
    styleUrls: ['./category-toolbar.component.scss']
})
export class CategoryToolbarComponent implements OnInit {
    @Input() viewMode: CategoryItemsViewMode = CategoryItemsViewMode.Cards;
    @Input() category: Category;
    @Input() sortField: string;
    @Input() sortOrder: SortOrder;
    @Output() viewModeChange = new EventEmitter<CategoryItemsViewMode>();
    @Output() sortFieldChange = new EventEmitter<string>();
    @Output() sortOrderChange = new EventEmitter<SortOrder>();
    sortOrderEnum = SortOrder;
    constructor() {}

    ngOnInit(): void {}

    sortOrderChangeHandler(sortOrder) {
        this.sortOrderChange.emit(sortOrder);
        return false;
    }
}
