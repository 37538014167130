import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Category, CategoryColumnSetting, CategoryItem, ColumnType } from '../../data/categories';
import {
    CategoryChosenSortField,
    CategoryChosenSortOrder,
    CategoryChosenViewMode,
    CategoryItemsViewMode,
    SortOrder
} from '../../data/data.models';
import { FieldHelper } from '../../utils/field-helper';

@Component({
    selector: 'dir-category-items',
    templateUrl: './category-items.component.html',
    styleUrls: ['./category-items.component.scss']
})
export class CategoryItemsComponent implements OnInit {
    @Input() category: Category;
    @Input() viewMode: CategoryItemsViewMode = CategoryItemsViewMode.Cards;
    @Input() sortField: string;
    @Input() sortOrder: SortOrder;
    @Input() categoryItems: CategoryItem[] = [];
    @Output() setViewMode = new EventEmitter<CategoryChosenViewMode>();
    @Output() setSortField = new EventEmitter<CategoryChosenSortField>();
    @Output() setSortOrder = new EventEmitter<CategoryChosenSortOrder>();
    viewModes = CategoryItemsViewMode;

    columnType = ColumnType;

    constructor() {}

    ngOnInit(): void {}

    getManagerCount(categoryItem: CategoryItem): number {
        return FieldHelper.getManagerCount(categoryItem);
    }

    getColumnType(columnSetting: CategoryColumnSetting) {
        const colDef = this.category.columnDefinitions.find((x) => x.name === columnSetting.originalName);
        if (colDef) {
            return colDef.type;
        }
    }
}
