import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntegrityService } from './services/integrity.service';
import { StoreModule } from '@ngrx/store';
import { integrityFeature } from './integrity.reducer';

@NgModule({
    declarations: [],
    imports: [CommonModule, StoreModule.forFeature(integrityFeature)],
    providers: [IntegrityService]
})
export class IntegrityModule {}
