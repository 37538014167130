<span [ngSwitch]="fieldType()">
    <span *ngSwitchDefault="" [class.blurred-text]="isBlurred"
          [innerHtml]="text|obscure:isBlurred">
    </span>
    <span *ngSwitchCase="'url'">
        <a [href]="text">{{text}}</a>
    </span>
    <span  *ngSwitchCase="'mail'" class="email" (click)="onComposeMail($event)">
        
        <span *ngIf="canChat()">
            <a href="#" (click)="onChat($event)">
                <i class="bi bi-chat-left-text me-2"></i>
            </a>            
        </span>
        <a href="mailto:{{text}}">{{text}}</a>
    </span>
</span>
