import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import * as signalR from '@microsoft/signalr';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class SignalRService {
    private hubConnection: signalR.HubConnection;
    private authToken: string;

    constructor() {}

    public startConnection() {
        console.log('startConnection');
        const opts: signalR.IHttpConnectionOptions = environment.skipWebSockets
            ? { accessTokenFactory: () => this.authToken, transport: signalR.HttpTransportType.ServerSentEvents }
            : { accessTokenFactory: () => this.authToken };
        this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl('/settingshub', opts)
            //.withUrl('/settingshub')
            .configureLogging(signalR.LogLevel.Warning)
            .build();
        this.hubConnection
            .start()
            .then(() => console.log('Settings Connection started'))
            //.then(() => this.getConnectionIdFromServer())
            .catch((err) => {
                console.warn('Error while starting connection: ' + err);
            });
        this.hubConnection.on('UserConnected', (name, tenant) => {
            console.log('UserConnected', name, tenant);
        });
    }

    public setSettingsListener(callback: (slice: string, timestamp: number) => void) {
        this.hubConnection.on('SettingsChanged', (settings, timestamp) => {
            callback(settings, timestamp);
        });
    }

    public setAuthToken(authToken: string) {
        this.authToken = authToken;
    }
}
