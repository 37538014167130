import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'obscure'
})
export class ObscureTextPipe implements PipeTransform {
    transform(txt: string, blur: boolean = false): string {
        if (!txt || txt.length === 0) {
            return '';
        }
        if (blur) {
            return '.'.repeat(txt.length);
        } else {
            return txt;
        }
    }
}
