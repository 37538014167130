<div class="modal-header">
    <h4 class="modal-title">Confirm changes to user data</h4>
</div>
<div class="modal-body">
    <div class="alert alert-danger d-flex align-items-center p-5">
        <i class="ki-duotone ki-shield-tick fs-2hx text-danger me-4"><span class="path1"></span><span
                class="path2"></span></i>
        <div class="d-flex flex-column">
            <h4 class="mb-1 text-danger">Please confirm changes to Microsoft Entra</h4>
            <span>Please confirm the following changes to your user data. These changes will be applied directly to your
                directory.</span>
        </div>
    </div>
    <div>
        <table class="table table-bordered">
            <thead>
                <tr class="fw-bold">
                    <th>Name</th>
                    <th>Field</th>
                    <th>Original value</th>
                    <th>Modified value</th>
                </tr>
            </thead>
            <tbody>
                @for (userAndModification of usersAndModifications; track userAndModification) {
                <tr>
                    <td colspan="1">{{userAndModification.user.displayName}}</td>
                    <td colspan="3"></td>
                </tr>
                @for (change of userAndModification.modifications; track change) {
                <tr>
                    <td></td>
                    <td>{{change.fieldName}}</td>
                    <td>{{userAndModification.user[change.fieldName]}}</td>
                    <td>{{change.newValue}}</td>
                </tr>
                }
                }
            </tbody>
        </table>
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-success" (click)="onApply()">Apply</button>
    <button class="btn btn-secondary" (click)="closeModal()">Cancel</button>
</div>
