<ng-container *ngIf="colDef.type === columnType.String || colDef.type === columnType.Link">
    <label class="fs-6 fw-bold form-label mt-2" for="fieldValue">{{group.controls.name.value}}</label>
    <input type="text" class="form-control form-control-solid" [formControl]="group.controls.value" name="fieldValue">
</ng-container>
<ng-container *ngIf="colDef.type === columnType.ItemCount">
    <label class="fs-6 fw-bold form-label mt-2">{{group.controls.name.value}}</label>
    <div>This field will show the number of people in this category item.</div>
</ng-container>
<ng-container *ngIf="colDef.type === columnType.ManagerCount">
    <label class="fs-6 fw-bold form-label mt-2">{{group.controls.name.value}}</label>
    <div>This field will show the number of people with direct reports in this category item.</div>
</ng-container>
