import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CategoryColumnSetting, CategoryItem, ColumnDefinition, ColumnType } from '../../data/categories';
import { FieldHelper } from '../../utils/field-helper';
@Component({
    selector: 'dir-category-tag',
    templateUrl: './category-tag.component.html',
    styleUrls: ['./category-tag.component.scss']
})
export class CategoryTagComponent implements OnChanges {
    columnDefinition: ColumnDefinition;
    @Input() columnSetting: CategoryColumnSetting;
    @Input() columnDefinitions: ColumnDefinition[];
    @Input() categoryItem: CategoryItem;

    columnType = ColumnType;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.columnSetting || changes.columnDefinitions) {
            this.columnDefinition = this.columnDefinitions.find((x) => x.name === this.columnSetting.originalName);
        }
    }

    getItemValue(): string {
        //debugger;
        if (
            this.categoryItem.columnValues &&
            this.columnDefinitions.findIndex((x) => x.name === this.columnSetting.originalName) !== -1
        ) {
            return this.categoryItem.columnValues[this.columnSetting.originalName];
        } else {
            return '';
        }
    }

    getManagerCount(): number {
        return FieldHelper.getManagerCount(this.categoryItem);
    }

    showValue(): boolean {
        if (this.columnDefinition == null) {
            return false;
        }
        if (
            this.columnDefinition.type === ColumnType.ItemCount ||
            this.columnDefinition.type == ColumnType.ManagerCount
        ) {
            return true;
        }

        if (this.columnDefinition.type === ColumnType.String) {
            return this.getItemValue() !== '';
        }

        return false;
    }
}
