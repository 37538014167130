import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as dataActions from '../../data/data.actions';
import * as integrityActions from '../../integrity/integrity.actions';
import { UserAndModifications } from 'src/app/integrity/integrity.models';

@Component({
    selector: 'dir-integrity-apply-modal',
    templateUrl: './integrity-apply-modal.component.html',
    styleUrl: './integrity-apply-modal.component.scss'
})
export class IntegrityApplyModalComponent {
    usersAndModifications: UserAndModifications[] = [];
    constructor(private store: Store) {}

    onApply() {
        this.store.dispatch(integrityActions.usersUpdate({ usersAndModifications: this.usersAndModifications }));
        this.closeModal();
    }

    closeModal() {
        this.store.dispatch(dataActions.dialogHide());
    }
}
