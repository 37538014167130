import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColumnDefinition, UserColumnSetting, UserColumnSettings } from 'src/app/data/categories';
import { DirectoryUser, GroupByField, SortField, SortOrder, UsersViewMode } from '../../data/data.models';

@Component({
    selector: 'dir-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarComponent implements OnInit {
    @Input() itemsCount = 0;
    @Input() pageSize = 10;
    @Input() sortField = 'firstName';
    @Input() sortOrder = SortOrder.Asc;
    @Input() groupByField: GroupByField = null;
    @Input() viewMode: UsersViewMode = UsersViewMode.Cards;
    @Input() userColumnSettings: UserColumnSettings | null = null;
    @Input() isCalendarHidden = false;
    @Output() pageSizeChange = new EventEmitter<number>();
    @Output() viewModeChange = new EventEmitter<UsersViewMode>();
    @Output() sortFieldChange = new EventEmitter<SortField>();
    @Output() sortOrderChange = new EventEmitter<SortOrder>();
    @Output() groupByChange = new EventEmitter<GroupByField>();
    sortOrderEnum = SortOrder;
    get groupByFields() {
        const result: Partial<{ [key in keyof DirectoryUser]: string }> = {
            department: 'Department',
            jobTitle: 'Job Title',
            city: 'City'
        };
        if (this.userColumnSettings) {
            const userColumnSetting =
                this.viewMode === UsersViewMode.Cards ? this.userColumnSettings.grid : this.userColumnSettings.table;
            userColumnSetting.forEach((column: UserColumnSetting) => {
                if (
                    column.originalName === 'displayName' ||
                    column.originalName === 'mail' ||
                    column.originalName === 'mobilePhone'
                ) {
                    return;
                }
                result[column.originalName] = column.name;
            });
        }
        return result;
    }
    get sortFields() {
        const result: Partial<{ [key in keyof DirectoryUser]: string }> = {
            firstName: 'First Name',
            lastName: 'Last Name',
            jobTitle: 'Job Title',
            city: 'City',
            department: 'Department'
        };
        if (this.userColumnSettings) {
            const userColumnSetting =
                this.viewMode === UsersViewMode.Cards ? this.userColumnSettings.grid : this.userColumnSettings.table;
            userColumnSetting.forEach((column: UserColumnSetting) => {
                result[column.originalName] = column.name;
            });
        }
        return result;
    }
    constructor() {}

    ngOnInit(): void {}

    sortFieldChangeHandler($event) {
        this.sortFieldChange.emit($event);
    }

    sortOrderChangeHandler($event) {
        this.sortOrderChange.emit($event);
        return false;
    }

    groupByChangeHandler($event: string) {
        this.groupByChange.emit($event as keyof DirectoryUser);
    }

    changePageSize($event) {
        this.pageSizeChange.emit(Number($event));
    }
}
