import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs';
import * as fromRoot from '../reducers';
import { DemoHelper } from '../utils/demo-helper';

@Component({
    selector: 'dir-consent-result',
    templateUrl: './consent-result.component.html',
    styleUrls: ['./consent-result.component.scss']
})
export class ConsentResultComponent {
    gotConsent = false;
    consentDenied = false;
    constructor(private router: Router, private store: Store) {
        this.store
            .pipe(
                select(fromRoot.selectQueryParam('admin_consent')),
                filter((x) => x != null)
            )
            .subscribe((consent) => {
                console.log('consent', consent);
                this.gotConsent = consent === 'True';
                this.consentDenied = !this.gotConsent;
                if (this.gotConsent) {
                    DemoHelper.DeactivateDemoMode();
                }
            });
    }

    goBackToConsentPage() {
        this.router.navigate(['/consent']);
    }

    goToDirectory() {
        this.router.navigate(['/directory']);
    }
}
