import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { BrowserAuthError, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RouterNavigatedAction, routerNavigatedAction } from '@ngrx/router-store';
import { select, Store } from '@ngrx/store';
import { EMPTY, catchError, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import * as AuthActions from './auth.actions';
import * as fromRoot from '../reducers';
import { TenantService } from '../services/tenant.service';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { Location } from '@angular/common';

@Injectable()
export class AuthEffects {
    constructor(
        private actions$: Actions,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private authService: MsalService,
        private tenantService: TenantService,
        private router: Router,
        private store: Store,
        private location: Location
    ) {}

    msalLogin$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.msalLogin),
                withLatestFrom(this.store.pipe(select(fromRoot.selectIsTeams))),
                switchMap(([action, isTeams]) => {
                    if (!isTeams) {
                        const isIframe = window !== window.parent && !window.opener;
                        if (isIframe) {
                            return this.authService
                                .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
                                .pipe(
                                    map((result) => {
                                        return AuthActions.msalLoginSuccess({ data: result.account });
                                    })
                                );
                        } else {
                            return this.authService
                                .loginRedirect({
                                    ...this.msalGuardConfig.authRequest,
                                    redirectStartPage: action.redirectStartPage ?? '/directory',
                                    loginHint: action.loginHint
                                } as RedirectRequest)
                                .pipe(
                                    map((result) => {
                                        return EMPTY;
                                    })
                                );
                        }
                    } else {
                        let request = {
                            ...this.msalGuardConfig.authRequest,
                            redirectStartPage: '/callback',
                            loginHint: action.loginHint
                            //prompt: 'consent'
                        } as RedirectRequest;
                        if (action.scopes && action.scopes.length > 0) {
                            //add scopes to request, but only if they are not already there
                            request.scopes = [...new Set([...request.scopes, ...action.scopes])];
                            request.scopes = request.scopes.filter((x) => x !== environment.apiScope);
                        }
                        return this.authService.loginRedirect(request).pipe(
                            map((result) => {
                                return EMPTY;
                            })
                        );
                    }
                })
            ),
        { dispatch: false }
    );

    msalLogout$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.msalLogout),
                switchMap(() => this.authService.logoutPopup({ mainWindowRedirectUri: '/' }))
            ),
        { dispatch: false }
    );

    redirectLoggedInUserToDirectory$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<RouterNavigatedAction>(routerNavigatedAction),
                tap((action: RouterNavigatedAction) => {
                    if (this.authService.instance.getAllAccounts().length > 0) {
                        if (action.payload.event.urlAfterRedirects === '/') {
                            //console.log('REDIRECTING TO DIRECTORY');
                            this.router.navigate(['/directory']);
                        }
                    }
                })
            ),
        { dispatch: false }
    );

    loadTenant$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.msalLoadLoggedInUser, AuthActions.loadTenant),
            withLatestFrom(this.store.pipe(select(fromRoot.selectIsFromToc))),
            switchMap(([action, isFromToc]) =>
                this.tenantService
                    .loadTenant(isFromToc || this.location.path()?.startsWith('/welcometoc') || this.location.path()?.startsWith('/fromtoc'))
                    .pipe(map((tenantAccount) => AuthActions.loadTenantSuccess({ tenantAccount })))
            ),
            // catchError((error) => {
            //     if (error instanceof BrowserAuthError && error.errorCode === 'user_cancelled') {
            //         return of(AuthActions.msalLogout());
            //     }
            //     if (error instanceof HttpErrorResponse && error.status === 401) {
            //         //return of(AuthActions.msalLogout());
            //     }
            //     return EMPTY;
            // })
        )
    );
}
