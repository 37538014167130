<div class="modal-header">
    <div class="card-title d-flex">
        <!--begin::Svg Icon | path: icons/duotune/communication/com005.svg-->
        <!-- <span class="svg-icon svg-icon-1 me-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                    d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z"
                    fill="black"></path>
                <path opacity="0.3"
                    d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z"
                    fill="black"></path>
            </svg>
        </span> -->
        <!--end::Svg Icon-->
        <!-- <h2>Details <span *ngIf="user"> - {{user.displayName}}</span></h2> -->
        <dir-chat [mail]="user?.mail" size="md"></dir-chat>
    </div>
    <button type="button" class="btn-close d-md-none close-big" (click)="closeModal()" aria-label="Close">
    </button>
    <button type="button" class="btn-close d-md-block d-none" (click)="closeModal()" aria-label="Close">
    </button>
</div>
<div class="modal-body">
    <div class="d-none">
        <button class="btn btn-primary mb-2" [disabled]="prevId === null" (click)="onChangePerson(prevId)">Prev</button>
        <button class="btn btn-primary ms-2 mb-2" [disabled]="nextId === null" (click)="onChangePerson(nextId)">
            Next
        </button>
        <button class="btn btn-primary ms-2 mb-2" [disabled]="managerId === null || managerId === ''"
            (click)="onChangePerson(managerId)">
            Manager
        </button>
    </div>
    <div *ngIf="hasNotBeenFound">
        The user has not been found.
    </div>
    <div class="row d-none d-lg-flex" *ngIf="user != null">
        <div class="col-6 col-lg-4">
            <dir-graph-picture *ngIf="true" [directoryUser]="user" [size]="200" [classes]="'mb-3'">
            </dir-graph-picture>
            <dir-user-calendar [email]="user?.mail" size="lg" (dismiss)="closeModal()"></dir-user-calendar>
        </div>
        <div class="col-6 col-lg-8">
            <h1 class="mb-0 d-flex flex-row">
                <dir-user-presence [id]="user.id" mode="square-xl"></dir-user-presence>
                <span [innerHtml]="user.displayName"></span>
            </h1>
            <h4 class="text-gray-700 mb-8">
                <dir-txt [text]="user.jobTitle"></dir-txt>
            </h4>
            <dir-details-field [user]="user" [userColumnSetting]="userColumnSetting" [categories]="categories"
                [hyperLinkCategories]="!isTeamsChannel"
                *ngFor="let userColumnSetting of (userColumnSettings$|async)?.details">
            </dir-details-field>
            <!--<dir-user-calendar [email]="user.mail" size="lg"></dir-user-calendar>-->
        </div>
    </div>
    <div class="row d-flex d-lg-none mb-2" *ngIf="user != null">
        <div class="col-4 col-lg-4">
            <dir-graph-picture [directoryUser]="user" [size]="100" [classes]="">
            </dir-graph-picture>
        </div>
        <div class="col-8 col-lg-8">
            <h1 class="mb-0">
                {{user.displayName}}
            </h1>
            <h4 class="text-gray-700 mb-8">
                <dir-txt [text]="user.jobTitle"></dir-txt>
            </h4>
        </div>
    </div>
    <div class="row d-flex d-lg-none" *ngIf="user != null">
        <div class="col">
            <dir-details-field [user]="user" [userColumnSetting]="userColumnSetting" [categories]="categories"
                [hyperLinkCategories]="!isTeamsChannel"
                *ngFor="let userColumnSetting of (userColumnSettings$|async)?.details">
            </dir-details-field>
            <dir-user-calendar [email]="user?.mail" size="lg" (dismiss)="closeModal()"></dir-user-calendar>
        </div>
    </div>
</div>
<div class="modal-footer justify-content-between">
    <div class="d-block">
        <button class="btn btn-primary" [disabled]="prevId === null" (click)="onChangePerson(prevId)">Prev</button>
        <button class="btn btn-primary ms-2" [disabled]="nextId === null" (click)="onChangePerson(nextId)">
            Next
        </button>
        <button class="btn btn-primary ms-2" *ngIf="!(managerId === null || managerId === '')"
            (click)="onChangePerson(managerId)">
            Manager
        </button>
    </div>
    <button class="btn btn-warning" (click)="closeModal()">Close</button>
</div>
