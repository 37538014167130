import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';

@Injectable()
export class InsightsService {
    private angularPlugin = new AngularPlugin();
    private clickPlugin = new ClickAnalyticsPlugin();
    private appInsights = new ApplicationInsights({
        config: {
            connectionString: environment.appInsightsConnectionString,
            enableAutoRouteTracking: true,
            extensions: [this.angularPlugin, this.clickPlugin],
            extensionConfig: {
                [this.angularPlugin.identifier]: { router: this.router, errorServices: [new ErrorHandler()] },
                [this.clickPlugin.identifier]: {
                    autoCapture: true,
                    clickTracking: true,
                    dataTags: { useDefaultContentNameOrId: true }
                }
            }
        }
    });
    constructor(private router: Router) {
        if (environment.appInsightsConnectionString) {
            this.appInsights.loadAppInsights();
            this.appInsights.trackPageView();
        }
    }

    trackEvent(name: string): void {
        this.appInsights.trackEvent({ name });
    }

    trackTrace(message: string): void {
        this.appInsights.trackTrace({ message });
    }

    setUser(username: string, tenantId: string) {
        this.appInsights.setAuthenticatedUserContext(username, tenantId);
    }
}
