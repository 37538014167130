import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import * as dataActions from '../data/data.actions';

@Component({
    selector: 'dir-department-editor-modal',
    templateUrl: './department-editor-modal.component.html',
    styleUrls: ['./department-editor-modal.component.scss']
})
export class DepartmentEditorModalComponent implements OnInit, OnDestroy {
    private unsubscribe$: Subject<void> = new Subject();
    constructor(private store: Store, private router: Router) {}

    ngOnInit(): void {}

    closeModal() {
        this.store.dispatch(dataActions.dialogHide());
    }

    ngOnDestroy(): void {
        this.router.navigate([], {
            queryParams: {
                userId: null
            },
            queryParamsHandling: 'merge'
        });
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
