import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as microsoftTeams from '@microsoft/teams-js';
import { TeamsEnvironment } from './app/auth/auth.models';

if (environment.production) {
    enableProdMode();
}
console.log(Date.now() + ' - starting platformBrowserDynamic');

//Is this TD hosted within TOC or SharePoint?
var inTOC = window.location && (window.location.href.indexOf("fromTOC") > -1 || window.location.href.indexOf("fromSP") > -1);

(window as any).isTeams = false;

if (!inTOC) {
    microsoftTeams.app
        .initialize()
        .then(
            () => {
                console.log(Date.now() + ' - initialized teams in main.ts');
                (window as any).isTeams = true;
                //microsoftTeams.app.notifySuccess();
                //Need the context so that we can tell which Teams mode we are running in.
                microsoftTeams.app
                    .getContext()
                    .then((context) => {
                        if (context != null) {
                            const channel: boolean =
                                (context.channel != undefined && context.channel != null) ||
                                (context.chat != undefined && context.chat != null);
                            const mobile: boolean =
                                context.app.host.clientType == microsoftTeams.HostClientType.android ||
                                context.app.host.clientType == microsoftTeams.HostClientType.ios;
                            let te: TeamsEnvironment = {
                                isTeams: true, isTeamsChannel: channel, isTeamsMobile: mobile, teamsTabUrl: null,
                                isOutlook: context?.app.host.name.toLocaleLowerCase().indexOf('outlook') != -1
                            };
                            (window as any).TeamsEnvironment = te;
                            console.log('TeamsEnvironment: ', te);
                        } else {
                            console.log('TeamsEnvironment: context is null');
                        }
                    })
                    .catch((reason) => {
                        console.log('main.ts : Error gettting Context', reason);
                    });
            },
            (reason) => {
                if (reason?.message !== 'Initialization Failed. No Parent window found.') {
                    console.log('microsoftTeams.app.initialize() failed', reason);
                }
                console.log(Date.now() + ' - microsoftTeams.app.initialize() - not in teams');
                (window as any).isTeams = false;
            }
        )
        .then(() => {
            console.log(Date.now() + ' - bootstrapping AppModule');
            platformBrowserDynamic()
                .bootstrapModule(AppModule)
                .then((moduleRef) => {
                })
                .catch((err) => {
                    console.log('platformBrowserDynamic() failed', err);
                    console.error(err);
                });
        });
} else {
    (window as any).isTeams = false;
    console.log(Date.now() + ' - bootstrapping AppModule');
    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .then((moduleRef) => {
        })
        .catch((err) => {
            console.log('platformBrowserDynamic() failed', err);
            console.error(err);
        });
}
