import * as microsoftTeams from '@microsoft/teams-js';

/**
 * Encapsulates the chat and email link functionality.
 */
export class ChatHelper {
    mail: string = '';
    isTeams: boolean = false;

    constructor(mail: string, isTeams: boolean) {
        this.mail = mail;
        this.isTeams = isTeams;
    }

    public onComposeMail(): boolean {
        if (this.isTeams && microsoftTeams.mail.isSupported()) {
            microsoftTeams.mail.composeMail({
                type: microsoftTeams.mail.ComposeMailType.New,
                toRecipients: [this.mail]
            });

            return true;
        }
        return false;
    }

    public onCall(users: string[]): string {
        const url = `msteams:/l/call/0/0?users=${users.join(',')}`;
        if (this.isTeams && microsoftTeams.call.isSupported()) {
            const callPromise = microsoftTeams.call.startCall({
                targets: users,
                requestedModalities: [microsoftTeams.call.CallModalities.Audio]});
            callPromise.
                then((result) => {
                    return ''; 
                })
                .catch((error) => {
                    const openWindow = () => window.open(url, '_blank', 'noreferrer');
                    microsoftTeams.app.openLink(url).then(() => { }, openWindow);                    
                });
            return '';
        } else {            
            return url;
        }        
    }

    public onChat(): string {
        const url = `msteams:/l/chat/0/0?users=${this.mail}`;
        const openWindow = () => window.open(url, '_blank', 'noreferrer');

        if (this.canChat()) {
            const chatPromise = microsoftTeams.chat.openChat({
                user: this.mail,
                message: ''
            });

            chatPromise
                .then((result) => {})
                .catch((error) => {
                    console.log('onChat : chatPromise.Error:', error);
                    microsoftTeams.app.openLink(url).then(() => {}, openWindow);
                });
            return '';
        } else if (this.isTeams) {
            microsoftTeams.app.openLink(url).then(() => {}, openWindow);
            return '';
        } else {            
            return url;
        }
    }

    public onGroupChat(topic: string, users: string[]): string {
        if (!topic || topic.length === 0) {
            topic = "Group Chat";
        }

        const names = users.join(',');
        const url = `msteams:/l/chat/0/0?users=${encodeURIComponent(names)}&topicName=${encodeURIComponent(topic)}`;
        const openWindow = () => window.open(url, '_blank', 'noreferrer');
        console.log("onGroupChat : start : url=", url);
        console.log("onGroupChat : start : users=", users);
        if (this.canChat()) {

            const chatPromise = microsoftTeams.chat
                .openGroupChat({
                    topic: topic,
                    users: users,
                    message: ''
                });
            // Scenario 1 : Microsoft host supports chat and in Teams.
         
            chatPromise.then((result) => {})
                .catch((error) => {
                    //Failed to chat.  Open the chat link as a fallback.
                    console.log("onGroupChat : chatPromise Error:", error);
                    microsoftTeams.app.openLink(url).then(() => {}, openWindow);
                    return '';
                });
            return '';
        } else if (this.isTeams) {
            console.log("onGroupChat : chatPromise inTeams openLink:",url);
            // Scenario 2 : Microsoft host does not support chat and in Teams.
            microsoftTeams.app.openLink(url).then(() => {}, openWindow);
            return '';
        } else {
            // Scenario 3 : Not in Teams. Probably in a browser.
            console.log("onGroupChat : chatPromise NOT inTeams openLink:", url);
            openWindow();
            return url;
        }
    }

    public canChat(): boolean {
        return this.isTeams && microsoftTeams.chat.isSupported();
    }


    private isValidEmail(): boolean {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(this.mail);
    }

    public composeMeeting(withWho: string[], startDateTime: string, endDateTime: string): boolean {

        if (this.isTeams) {
            if (microsoftTeams.calendar.isSupported()) {
                microsoftTeams.calendar.composeMeeting({
                    attendees: withWho,
                    startTime: startDateTime,
                    endTime: endDateTime,
                    subject: 'Meeting to discuss bug',
                    content: 'Discuss the correct format to the composeMeeting API call'
                });
            } else {
                microsoftTeams.executeDeepLink(
                    ChatHelper.composeMeetingDeeplink(withWho, startDateTime, endDateTime)
                );
            }
            return true;
        } else {
            return false;
        }
    }

    public static composeMeetingDeeplink(withWho: string[], startTime: string, endTime: string): string {
        return `msteams:/l/meeting/new?subject=${encodeURIComponent('Meeting')}&startTime=${startTime}&endTime=${endTime}&attendees=${withWho.join(',')}`;
    }

}
