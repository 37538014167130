import { Injectable } from '@angular/core';

export enum UISetting {
    Calendar_Compare_With_Me ='calendar-compare-With-Me',
    Calendar_Labels = 'calendar-labels',
    Calendar_Alert_Dismissed = 'calendar-alert-dismissed',
    Calendar_Show12Time = 'calendar-show-12-time',
    Availability_View = 'availability-view',

}

@Injectable({ providedIn: 'root' })
export class UISettingsService {

    constructor() { }

    getBoolValue(settingName: UISetting, defaultValue: boolean = false): boolean {
        const valueStr = localStorage.getItem(`${settingName}`);
        if (valueStr === undefined || valueStr === null || valueStr === '' || valueStr === 'false') {
            return defaultValue;
        }
        return true;
    }

    setBoolValue(settingName: UISetting, value: boolean): boolean {
        localStorage.setItem(`${settingName}`, JSON.stringify(value));
        return value;
    }

    toggleBoolValue(settingName: UISetting): boolean {
        const b = !this.getBoolValue(settingName);
        localStorage.setItem(`${settingName}`, JSON.stringify(b));
        return b;
    }
}
