import { FILL_COLOR_DARK, FILL_COLOR_LIGHT, OPACITY_MAX, OPACITY_MIN } from './constants';

/**
 * Extract a substring from a hex string and parse it as an integer
 * @param {string} hash - Source hex string
 * @param {number} index - Start index of substring
 * @param {number} [length] - Length of substring. Defaults to 1.
 */
export function hexVal(hash: string, index: number, len?: number) {
    return parseInt(hash.substr(index, len || 1), 16);
}

/*
 * Re-maps a number from one range to another
 * http://processing.org/reference/map_.html
 */
export function map(value, vMin, vMax, dMin, dMax) {
    var vValue = parseFloat(value);
    var vRange = vMax - vMin;
    var dRange = dMax - dMin;

    return ((vValue - vMin) * dRange) / vRange + dMin;
}

export function fillColor(val) {
    return val % 2 === 0 ? FILL_COLOR_LIGHT : FILL_COLOR_DARK;
}

export function fillOpacity(val) {
    return map(val, 0, 15, OPACITY_MIN, OPACITY_MAX);
}
