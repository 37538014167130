import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import {
    MSAL_GUARD_CONFIG,
    MsalBroadcastService,
    MsalGuardAuthRequest,
    MsalGuardConfiguration,
    MsalService
} from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { Store, select } from '@ngrx/store';
import { combineLatest, filter, Subject, take, takeUntil, withLatestFrom } from 'rxjs';
import * as AuthActions from './auth/auth.actions';
import * as DataActions from './data/data.actions';
import * as UiActions from './ui/ui.actions';
import * as settingsActions from './settings/settings.actions';
import * as fromRoot from './reducers';
import { uiFeature } from './ui/ui.reducer';
import { ActivatedRoute, Event, Router } from '@angular/router';
import { EventType as RouterEventType } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { initLogger } from './utils/logger';
import { TeamsEnvironment } from './auth/auth.models';
import * as microsoftTeams from '@microsoft/teams-js';
import { environment } from 'src/environments/environment';
import { SignalRService } from './services/signalr.service';
import { DataSourceHelper, MagicMissingDataSourceId } from './utils/data-source-helper';
import { HttpParams } from '@angular/common/http';
import { InsightsService } from './services/insights.service';

@Component({
    selector: 'dir-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    private readonly unsubscribe$ = new Subject<void>();
    isLoggedIn = false;
    isIframe = false;
    shouldShowLogin = false;
    latestScopes: string[] = [];
    private teamsTabUrl: string = '';
    private hasSetTeamsTabUrl = false;
    private isFirstNavigation = true;
    private tocLoginHint = '';
    private tocTenantId = '';
    constructor(
        private store: Store,
        private authService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private router: Router,
        private logger: NGXLogger,
        private location: Location,
        private signalRService: SignalRService,
        private ngZone: NgZone,
        private route: ActivatedRoute,
        private insights: InsightsService
    ) {
        this.logger.trace('AppComponent constructor');
        this.logger.info('isTeams', (window as any).isTeams);
        this.store.dispatch(AuthActions.setIsTeams({ isTeams: (window as any).isTeams === true }));

        if ((window as any).TeamsEnvironment) {
            const teamsEnvironment: TeamsEnvironment = (window as any).TeamsEnvironment;
            this.store.dispatch(AuthActions.setTeamsEnvironment({ teamsEnvironment }));
            this.logger.debug('App.component.ts : TeamsEnvironment found', teamsEnvironment);
        } else {
            this.logger.debug('App.component.ts : TeamsEnvironment not found');
        }

        initLogger(logger);
    }

    ngOnInit(): void {
        this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((event: Event) => {
            if (event.type === RouterEventType.NavigationStart) {
                if (this.isFirstNavigation) {
                    this.isFirstNavigation = false;
                    if (this.location.path().startsWith('/directory/search/')) {
                        this.store.dispatch(UiActions.appEntryPointSearchPage());
                    }
                }
            }
        });
        this.store
            .pipe(select(fromRoot.selectQueryParam('tocLoginHint')), takeUntil(this.unsubscribe$))
            .subscribe((x) => (this.tocLoginHint = x));
        this.store
            .pipe(select(fromRoot.selectQueryParam('tocTenantId')), takeUntil(this.unsubscribe$))
            .subscribe((x) => (this.tocTenantId = x));
        this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
            if (params['tocTenantId']) {
                this.tocTenantId = params['tocTenantId'];
            }
            if (params['tocLoginHint']) {
                this.tocLoginHint = params['tocLoginHint'];
            }
        });
        combineLatest([
            this.router.routerState.root.queryParams,
            this.store.pipe(select(fromRoot.selectTeamsEnvironment))
        ])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(([params, teamsEnvironment]) => {
                if (this.hasSetTeamsTabUrl) {
                    return;
                }
                if (params['teamstab']) {
                    this.logger.debug('AppComponent : teamstab', params['teamstab']);
                    //remove query from path
                    this.teamsTabUrl = this.location.path();
                    this.teamsTabUrl = this.teamsTabUrl.replace('?teamstab=true', '');
                    //this.store.dispatch(AuthActions.setIsTeamsTab({ isTeamsTab: params['teamstab'] === 'true' }));
                }
                if (teamsEnvironment && this.teamsTabUrl !== '') {
                    this.store.dispatch(
                        AuthActions.setTeamsEnvironment({
                            teamsEnvironment: { ...teamsEnvironment, teamsTabUrl: this.teamsTabUrl }
                        })
                    );
                    this.hasSetTeamsTabUrl = true;
                }
            });
        this.isIframe = window !== window.parent && !window.opener;
        const isDemo = localStorage.getItem('isDemo');
        this.store.dispatch(AuthActions.setIsDemo({ isDemo: isDemo === 'true' }));
        this.setIsLoggedIn();
        this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
        this.msalBroadcastService.msalSubject$
            .pipe(
                filter(
                    (msg: EventMessage) =>
                        msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED
                )
            )
            .subscribe((result: EventMessage) => {
                if (this.authService.instance.getAllAccounts().length === 0) {
                    window.location.pathname = '/';
                } else {
                    this.setIsLoggedIn();
                }
            });

        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this.unsubscribe$),
                withLatestFrom(this.store.pipe(select(fromRoot.selectUser)))
            )
            .subscribe(([event, user]) => {
                this.logger.debug(this.location.path());

                if (
                    !this.location.path()?.startsWith('/callback') &&
                    !this.location.path()?.startsWith('/auth-teams')
                ) {
                    this.setIsLoggedIn();
                    this.checkAndSetActiveAccount();
                    const activeUser = this.authService.instance.getActiveAccount();
                    if (user != null && activeUser != null && user.username !== activeUser.username) {
                        this.logger.debug('user changed, dispatching load user');
                        this.store.dispatch(
                            AuthActions.msalLoadLoggedInUser({
                                data: {
                                    ...activeUser,
                                    tenantProfiles: Array.from(activeUser.tenantProfiles?.values() || [])
                                }
                            })
                        );
                    }
                    if (user == null && activeUser != null) {
                        this.logger.debug('user was null, dispatching load user');
                        this.store.dispatch(
                            AuthActions.msalLoadLoggedInUser({
                                data: {
                                    ...activeUser,
                                    tenantProfiles: Array.from(activeUser.tenantProfiles?.values() || [])
                                }
                            })
                        );
                    }
                }
            });

        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
                takeUntil(this.unsubscribe$),
                withLatestFrom(
                    this.store.pipe(select(fromRoot.selectIsTeams)),
                    this.store.pipe(select(fromRoot.selectIsTeamsInteractionInProgress)),
                    this.store.pipe(select(fromRoot.selectIsDemo))
                )
            )
            .subscribe(([result, isTeams, isTeamsInteractionInProgress, isDemo]) => {
                if (isDemo) {
                    this.logger.info('DEMO MODE, NOT AUTHENTICATING');
                    return;
                }
                if (!isTeams && !(window as any).isTeams) {
                    return;
                }
                this.logger.warn('acquire token failure detected ' + JSON.stringify(result));
                this.logger.warn(result);
                this.logger.warn('LATEST SCOPES WERE:', this.latestScopes);
                this.logger.warn('TEAMS:', isTeams);
                if (isTeamsInteractionInProgress) {
                    this.logger.warn('TEAMS INTERACTION IN PROGRESS, NOT AUTHENTICATING');
                    return;
                }
                if (this.latestScopes.length === 0) {
                    this.logger.warn('NO SCOPES, NOT AUTHENTICATING');
                    return;
                }
                if (isTeams || (window as any).isTeams === true) {
                    let request: MsalGuardAuthRequest = { ...this.msalGuardConfig.authRequest };
                    //add scopes to request, but only new ones
                    request.scopes = [...new Set([...request.scopes, ...this.latestScopes])];
                    if (request.scopes.length === 1 && request.scopes[0] === environment.apiScope) {
                        this.logger.info('only api scope, not authenticating');
                        return;
                    }
                    microsoftTeams.app.initialize().then(() => {
                        this.logger.debug('teams api initialized in app.component.ts');
                        this.store.dispatch(AuthActions.startTeamsInteraction());
                        microsoftTeams.authentication
                            .authenticate({
                                url: window.location.origin + '/auth-teams-scopes?scopes=' + request.scopes.join(','),
                                height: 640
                            })
                            .then(
                                (result) => {
                                    this.logger.debug('success, reloading');
                                    this.store.dispatch(AuthActions.endTeamsInteraction());
                                    location.reload();
                                },
                                (reason) => {
                                    this.logger.warn('INTERACTION FAILED IN APP.COMPONENT.TS');
                                    this.logger.warn('failure', reason);
                                    this.logger.warn(JSON.stringify(reason));
                                    this.store.dispatch(AuthActions.endTeamsInteraction());
                                    if (reason.message === 'consent_required' || reason.message === 'CancelledByUser') {
                                        this.router.navigate(['/consent']);
                                    }
                                }
                            );
                    });
                }
            });

        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_START),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((result: EventMessage) => {
                if ((result.payload as RedirectRequest).scopes) {
                    this.latestScopes = [
                        ...new Set([...this.latestScopes, ...(result.payload as RedirectRequest).scopes])
                    ];
                }
            });

        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((result: EventMessage) => {
                this.logger.debug('ACQUIRE TOKEN SUCCESS');
                ////log the scopes we got
                this.logger.debug('SCOPES:', (result.payload as AuthenticationResult).scopes);
                if ((result.payload as AuthenticationResult).scopes) {
                    if ((result.payload as AuthenticationResult).scopes.includes(environment.apiScope)) {
                        this.logger.debug('setting signalr auth token');
                        this.signalRService.setAuthToken((result.payload as AuthenticationResult).accessToken);
                    }
                }
            });

        this.store
            .pipe(
                select(fromRoot.selectRouterState),
                filter((x) => x != null),
                take(1),
                withLatestFrom(
                    this.store.select(fromRoot.selectIsFromTocQueryParam),
                    this.store.select(fromRoot.selectChartId)
                )
            )
            .subscribe(([routerState, isFromTocQueryParam, chartId]) => {
                this.logger.log('------------------------ INITIAL DATA SOURCE SETUP ------------------------');
                this.logger.log('router state', routerState);
                this.logger.log('isFromToc', isFromTocQueryParam);
                this.logger.log('chartId', chartId);
                if (isFromTocQueryParam) {
                    this.store.dispatch(
                        DataActions.setDataSource({
                            isFromToc: isFromTocQueryParam,
                            chartId: isFromTocQueryParam ? chartId : null
                        })
                    );
                } else {
                    const chartId = DataSourceHelper.GetSelectedDataSourceChartId();
                    const dataSourceId = DataSourceHelper.GetSelectedDataSourceId();
                    this.store.dispatch(
                        DataActions.setDataSource({ isFromToc: isFromTocQueryParam, chartId: chartId, dataSourceId })
                    );
                }
            });

        this.store
            .pipe(
                select(fromRoot.selectTenantDataSourceCheckData),
                takeUntil(this.unsubscribe$),
                filter((x) => x != null)
            )
            .subscribe(({ tenantAccount, isAdmin, isFromToc, chartId, dataSourceId, isDemo }) => {
                //FIXME: Firing two times on page load
                this.logger.log('------------------------ TENANT DATA SOURCE CHECK ------------------------');
                //this.store.pipe(select(fromRoot.selectTenantAccount), takeUntil(this.unsubscribe$)).subscribe((account) => {
                if (isFromToc && tenantAccount && chartId && !isDemo) {
                    if (dataSourceId == null) {
                        let dataSource = tenantAccount.dataSources.find((x) => x.chartId === chartId);
                        this.store.dispatch(
                            DataActions.setDataSource({
                                isFromToc,
                                chartId: chartId,
                                dataSourceId: dataSource?.id ?? MagicMissingDataSourceId
                            })
                        );
                    }
                }
                if (tenantAccount && !isFromToc && !isDemo) {
                    //check selected data source
                    let selectedDataSourceId = DataSourceHelper.GetSelectedDataSourceId();
                    if (!DataSourceHelper.HasPreference()) {
                        //no selected data source, check if tenant has a default
                        if (tenantAccount.dataSources.find((x) => x.isDefault)) {
                            //tenant has a default, set it
                            DataSourceHelper.SetSelectedDataSourceId(
                                tenantAccount.dataSources.find((x) => x.isDefault)
                            );
                            window.location.reload();
                        }
                    } else {
                        //selected data source, check if it exists in tenant
                        if (
                            selectedDataSourceId !== 0 &&
                            !tenantAccount.dataSources.find((x) => x.id === selectedDataSourceId)
                        ) {
                            //selected data source not found in tenant, reset to default
                            const defaultSource = tenantAccount.dataSources.find((x) => x.isDefault);
                            if (defaultSource) {
                                DataSourceHelper.SetSelectedDataSourceId(defaultSource);
                            } else {
                                DataSourceHelper.UnsetSelectedDataSourceId();
                            }
                            window.location.reload();
                        } else if (!isAdmin) {
                            //check if there's a data source with lock flag
                            let lockedDataSource = tenantAccount.dataSources.find(
                                (x) => x.isDefault && x.shouldLockRegularUsers
                            );
                            if (lockedDataSource && lockedDataSource.id !== selectedDataSourceId) {
                                DataSourceHelper.SetSelectedDataSourceId(lockedDataSource);
                                window.location.reload();
                            }
                        }
                    }
                    this.signalRService.startConnection();
                    this.signalRService.setSettingsListener((settings, timestamp) => {
                        this.logger.info('settings received', settings, timestamp);
                        this.ngZone.run(() => {
                            this.store.dispatch(settingsActions.loadSettings());
                        });
                    });
                }
            });

        this.store.pipe(select(uiFeature.selectTheme), takeUntil(this.unsubscribe$)).subscribe((theme) => {
            this.logger.trace('theme changed', theme);
            //handle auto theme
            if (theme === 'auto') {
                //get user's preferred theme
                theme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
            }
            //set <html> element data-bs-theme attribute
            document.documentElement.setAttribute('data-bs-theme', theme);
        });
    }

    setIsLoggedIn() {
        this.isLoggedIn = this.authService.instance.getAllAccounts().length > 0;
    }

    checkAndSetActiveAccount() {
        /**
         * If no active account set but there are accounts signed in, sets first account to active account
         * To use active account set here, subscribe to inProgress$ first in your component
         * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
         */
        this.logger.info('checkAndSetActiveAccount');
        let activeAccount = this.authService.instance.getActiveAccount();
        this.tocLoginHint = this.getParamValueQueryString('tocLoginHint') ?? '';
        this.tocTenantId = this.getParamValueQueryString('tocTenantId') ?? '';

        const setActiveAccountFromAccounts = () => {
            this.logger.info('setting active account from accounts');
            let accounts = this.authService.instance.getAllAccounts();
            this.authService.instance.setActiveAccount(accounts[0]);
        };

        if (
            this.authService.instance.getAllAccounts().length > 0 &&
            (!activeAccount ||
                (this.tocLoginHint !== '' && activeAccount.username.toLowerCase() !== this.tocLoginHint.toLowerCase()))
        ) {
            //try to read query params directly

            if (this.tocLoginHint !== '' || this.tocTenantId !== '') {
                this.logger.info('setting active account from toc');
                let account = this.authService.instance.getAllAccounts().find((x) => x.username === this.tocLoginHint);
                if (account) {
                    this.logger.info('setting active account from toc', account);
                    this.authService.instance.setActiveAccount(account);
                } else {
                    setActiveAccountFromAccounts();
                }
            } else {
                setActiveAccountFromAccounts();
            }
        }
        activeAccount = this.authService.instance.getActiveAccount();
        if (activeAccount) {
            console.log('setting insights user context', activeAccount.username, activeAccount.tenantId);
            this.insights.setUser(activeAccount.username, activeAccount.tenantId);
        }
    }

    logout() {
        this.store.dispatch(AuthActions.msalLogout());
    }

    private getParamValueQueryString(paramName: string): string {
        const url = window.location.href;
        let paramValue;
        if (url.includes('?')) {
            const httpParams = new HttpParams({ fromString: url.split('?')[1] });
            paramValue = httpParams.get(paramName);
        }
        return paramValue;
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
