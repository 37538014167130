<div *ngIf="user[userColumnSetting.originalName]" class="row mb-4" [ngSwitch]="userColumnSetting.originalName">
    <div class="col-4" *ngSwitchDefault="">
        <div class="fw-bold text-muted d-flex flex-row align-items-center">
            <div *ngIf="getCategoryIcon()" class="fw-bold text-muted me-4">
                <i class="bi {{getCategoryIcon()}} fs-1"></i>
            </div>
            <div *ngIf="!isCategory() || !hyperLinkCategories" [innerHtml]="userColumnSetting.name"></div>
            <div *ngIf="isCategory()">
                <a *ngIf="hyperLinkCategories" (click)="closeModal()" routerLink="/directory/{{getCategoryName()}}"
                    [innerHtml]="userColumnSetting.name"></a>
            </div>
        </div>
    </div>
    <div class="col-8 d-flex align-items-center" *ngSwitchDefault="">
        <div *ngIf="isCategory() && user" class="fw-bolder fs-6 text-gray-800">
            <div class="fw-bolder fs-6 text-gray-800">
                <a *ngIf="hyperLinkCategories" (click)="closeModal()"
                    routerLink="/directory/{{getCategoryName()}}/{{user[userColumnSetting.originalName]}}">
                    <dir-txt [text]="user[userColumnSetting.originalName]"></dir-txt>
                </a>
                <dir-txt *ngIf="!hyperLinkCategories" [text]="user[userColumnSetting.originalName]"></dir-txt>
            </div>
        </div>
        <span *ngIf="!isCategory() && user" class="fw-bolder fs-6 text-gray-800">
            <dir-txt [text]="user[userColumnSetting.originalName]"></dir-txt>
        </span>
    </div>
</div>
