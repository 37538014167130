import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'highlightSearch'
})
export class HighlightSearchPipe implements PipeTransform {
    transform(value: string, ...args: string[]): unknown {
        if (!args) {
            return value;
        }

        const regex = new RegExp(args[0], 'gi');
        const match = value.match(regex);

        if (!match) {
            return value;
        }

        return value.replace(regex, `<span class='highlight-search'>${match[0]}</span>`);
    }
}
