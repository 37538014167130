import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppDB } from '../services/db.service';
import { ImageService } from '../services/image.service';
import * as ImageActions from './image.actions';

@Injectable()
export class ImageEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private db: AppDB,
        private imageService: ImageService
    ) {}

    saveImage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ImageActions.saveImage),
            switchMap((action) => {
                //Save to server
                this.imageService
                    .saveImage(action.image)
                    .pipe()
                    .subscribe((x) => {});
                //Save to localDB
                return this.db.saveImage(action.image).pipe(
                    switchMap(() => {
                        this.imageService.refreshImage(action.image.id);
                        return of(ImageActions.saveImageSuccess({ id: action.image.id }));
                    })
                );
            })
        );
    });

    //FIXME: Vlad: I think this is broken
    loadImageFromServer$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ImageActions.loadImageFromServer),
            switchMap((action) => {
                this.imageService
                    .loadImageFromServer(action.imageId)
                    .pipe()
                    .subscribe((ci) => {
                        this.db.saveImage(ci);
                    });
                return of(ImageActions.loadImageFromServerSuccess({ imageId: action.imageId }));
            })
        );
    });

    loadAllImagesFromServer$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ImageActions.loadAllImagesFromServer),
            switchMap((action) => {
                //
                //TO Discuss - best place to store "sync" time
                //

                const t = localStorage.getItem('IMAGE-SYNC-TIME');
                localStorage.setItem('IMAGE-SYNC-TIME', new Date().getTime().toString());
                const d: Date = new Date();
                d.getTime();
                let timeStamp = 0;
                if (t && t.length != 0) {
                    timeStamp = parseInt(t);
                }

                this.imageService
                    .loadAllImages(timeStamp)
                    .pipe()
                    .subscribe((ci) => {
                        console.log(ci);
                        if (ci && ci.length != 0) {
                            ci.forEach((i) => {
                                this.db.saveImage(i);
                            });
                        }
                    });
                return [ImageActions.loadAllImagesFromServerSuccess()];
            })
        );
    });
}
