import { Component } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import { Store, select } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import * as fromRoot from '../../reducers';
import { DirectoryUser } from 'src/app/data/data.models';

@Component({
    selector: 'dir-calendar',
    templateUrl: './calendar.component.html',
    styleUrl: './calendar.component.scss'
})
export class CalendarComponent {
    calendarOptions: CalendarOptions = {
        plugins: [dayGridPlugin],
        initialView: 'dayGridMonth',
        validRange: {
            //start 6 months ago
            start: new Date(new Date().setMonth(new Date().getMonth() - 6)),
            //end 6 months from now
            end: new Date(new Date().setMonth(new Date().getMonth() + 12))
        },
        weekends: true
    };
    users$ = this.store.pipe(select(fromRoot.selectDisplayedUsersForCalendar));

    constructor(private store: Store) {
        this.calendarOptions.eventDidMount = (info) => {
            //add bootstrap cake icon
            const icon = document.createElement('i');
            if (info.event.classNames.indexOf('bg-success') > -1) {
                icon.className = 'bi bi-briefcase-fill text-white mx-1';
            } else {
                icon.className = 'bi bi-cake-fill text-white mx-1';
            }
            //info.el.prepend(icon);
            info.el.querySelector('.fc-event-title')?.prepend(icon);
        };
        this.users$.pipe(takeUntilDestroyed()).subscribe((users) => {
            this.calendarOptions.events = [
                ...this.generateEvents(users, 'birthday', 'bg-primary'),
                ...this.generateEvents(users, 'employeeHireDate', 'bg-success')
            ];
        });
    }

    private generateEvents(users: DirectoryUser[], dateKey: keyof DirectoryUser, className: string) {
        const currentDate = new Date();
        const sixMonthsAgo = new Date();
        sixMonthsAgo.setMonth(currentDate.getMonth() - 6);
        const oneYearAhead = new Date();
        oneYearAhead.setFullYear(currentDate.getFullYear() + 1);

        return users
            .filter((user) => user[dateKey] != null && !user[dateKey]?.startsWith('0001-01-01'))
            .flatMap((user) => {
                const date = new Date(user[dateKey]);
                const events = [];

                for (let year = sixMonthsAgo.getFullYear(); year <= oneYearAhead.getFullYear(); year++) {
                    const eventDate = new Date(date);
                    eventDate.setFullYear(year);

                    if (eventDate >= sixMonthsAgo && eventDate <= oneYearAhead) {
                        events.push({
                            title: user.displayName,
                            start: eventDate,
                            allDay: true,
                            className: className
                        });
                    }
                }

                return events;
            });
    }
}
