import { Store, select } from '@ngrx/store';
import { AppDB } from './db.service';
import { TocService } from './toc.service';
import { IDataService } from './data.service';
import { DemoDataService } from './demo-data.service';
import { ChartService } from './chart.service';
import { AzureDataService } from './azure.service';
import { HttpClient } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import * as fromRoot from '../reducers';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { DefaultAzureSourceChartId } from '../utils/data-source-helper';
import { AzureDeltaService } from './azure-delta.service';
import { FeatureService } from '../shared/services/feature.service';

@Injectable({ providedIn: 'root' })
export class DataServiceProvider {
    private dataSource?: string;
    private dataService?: IDataService;
    constructor(
        private store: Store,
        private db: AppDB,
        private tocService: TocService,
        private http: HttpClient,
        private featureService: FeatureService,
        private authService: MsalService,
        private logger: NGXLogger
    ) {
        this.store.pipe(select(fromRoot.selectSelectedDataSourceChartId)).subscribe((chartId) => {
            //this.logger.warn('chartId', chartId);
            if (this.dataSource !== chartId) {
                this.dataSource = chartId;
                //this.dataService = this.createDataService();
            }
        });
    }

    public getDataService(): IDataService {
        this.logger.log('getDataService', this.dataSource);
        if (!this.dataService) {
            this.dataService = this.createDataService();
        }
        return this.dataService;
    }

    private createDataService(): IDataService {
        this.logger.log('createDataService', this.dataSource);
        const isDemo = localStorage.getItem('isDemo');
        if (isDemo === 'true') {
            return new DemoDataService(this.store, this.db);
        }
        if (this.dataSource && this.dataSource !== DefaultAzureSourceChartId) {
            return new ChartService(this.store, this.db, this.tocService);
        }
        return new AzureDeltaService(this.http, this.store, this.db, this.featureService); //, this.authService);
    }
}
