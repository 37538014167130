import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subject, combineLatest, takeUntil } from 'rxjs';
import { UserColumnSettings } from 'src/app/data/categories';
import { DirectoryUser } from 'src/app/data/data.models';
import { DataSourceType } from 'src/app/services/data-service.factory';
import * as dataActions from '../../data/data.actions';
import * as fromRoot from '../../reducers';
import * as availabilityActions from '../../userAvailability/availability.actions';
import * as uiActions from '../../ui/ui.actions';
import { uiFeature } from 'src/app/ui/ui.reducer';
import { FeatureService } from '../../shared/services/feature.service';
import { Feature } from '../../shared/components/features/features.models';

@Component({
    selector: 'dir-users-table',
    templateUrl: './users-table.component.html',
    styleUrls: ['./users-table.component.scss'],
    //changeDetection: ChangeDetectionStrategy.OnPush
})
export class UsersTableComponent implements OnInit, OnDestroy {
    private _users: DirectoryUser[] = [];
    private usersSubject: Subject<DirectoryUser[]> = new Subject<DirectoryUser[]>();
    users$ = this.usersSubject.asObservable();

    @Input()
    set users(value: DirectoryUser[]) {
        this._users = value;
        this.usersSubject.next(value);
    }

    get users(): DirectoryUser[] {
        return this._users;
    }
    @Input() dataSourceType: DataSourceType;
    @Input() userColumnSettings: UserColumnSettings = null;
    @Input() favorites: string[] = [];
    private unsubscribe$: Subject<void> = new Subject();

    areAllSelected: boolean = false;
    inTeams = false;
    selectedUsers: DirectoryUser[] = [];
    presenceFeatureConsented = false;

    constructor(private router: Router,
        private store: Store,
        private featureService: FeatureService) { }

    ngOnInit(): void {
        this.store.pipe(takeUntil(this.unsubscribe$), select(fromRoot.selectTeamsEnvironment)).subscribe((env) => {
            if (env) {
                this.inTeams = env.isTeams;
            }
        });

        this.store
            .pipe(select(uiFeature.selectSelectedUsers))
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((users) => {
                this.selectedUsers = users;
            });

        combineLatest([
            this.featureService.hasFeature(Feature.UserAvailability),
            this.users$])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(([isPresenceAvailable, users]) => {
                this.presenceFeatureConsented = isPresenceAvailable;
                if (this.users.length > 0 && isPresenceAvailable) {

                    const ids: string[] = users.map((u) => u.id);
                    this.store.dispatch(availabilityActions.loadUserPresences({ ids: ids }));
                }
            }
        );

        this.usersSubject.next(this.users);
    }

    onSelectAll($event) {
        $event.stopPropagation();
        this.users.forEach((user) => {
            this.store.dispatch(uiActions.selectDeselectUser({ user }));
        });
    }

    onSelect($event, user: DirectoryUser) {
        $event.stopPropagation();
        this.store.dispatch(uiActions.selectDeselectUser({ user }));
    }

    isChecked(user: DirectoryUser): boolean {
        return this.selectedUsers.findIndex((u) => u.id === user.id) > -1;
    }

    toggleFavorite($event, user: DirectoryUser) {
        $event.stopPropagation();
        $event.preventDefault();
        this.store.dispatch(dataActions.toggleFavorite({ id: user.id }));
        return false;
    }

    isFavorite(user: DirectoryUser): boolean {
        return this.favorites.includes(user.id);
    }

    prevent($event) {
        $event.stopPropagation();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.usersSubject.complete();
    }
}
