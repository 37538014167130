
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store} from '@ngrx/store';
import { Subject } from 'rxjs';
import { DirectoryUser } from '../../data/data.models';
import { UserAvailability } from '../availability.models';
import * as availabilityActions from '../../userAvailability/availability.actions';

@Component({
    selector: 'dir-user-availability-strip',
    templateUrl: './user-availability-strip.component.html',
    styleUrls: ['./user-calendar.component.scss']
})
export class UserAvailabilityStripComponent implements OnInit, OnDestroy {

    private unsubscribe$: Subject<void> = new Subject();
    
    @Input() availability = '';
    @Input() userAvailability: UserAvailability = null;
    @Input() users: DirectoryUser[] = [];
    @Input() dayModifier = 0;

    
    constructor(private store: Store) { }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    ngOnInit(): void {      
    }

    formatTime(time: string): string {
        const date = new Date(time);
        const options: Intl.DateTimeFormatOptions = {
            //weekday: 'short', // Short weekday name (e.g., "Mon")
            //month: 'short',   // Short month name (e.g., "Oct")
            //day: 'numeric',   // Day of the month as a number (e.g., "18")
            //year: '2-digit',  // 4-digit year (e.g., "2023")
            hour: 'numeric',  // Hour in 12-hour format (e.g., "3")
            minute: 'numeric', // Minutes (e.g., "30")
            hour12: true  
        };

        const formattedDate = date.toLocaleString(undefined,options);
        
        return formattedDate;
    }

    getAvailability(): string[] {
        if (this.availability) {
            return this.availability.split('');
        }
        return [];
    }

    //Calcualtes a new date based on the period ( number of 15 minute intervals )
    calculateTime(period: number): Date {        
        const minutesToAdd = period * 15;        
        const startdate = new Date(this.userAvailability.WorkingHours.StartTime);        
        const minutes = startdate.getUTCMinutes();        
        startdate.setMinutes(minutes + minutesToAdd);
        return startdate;
    }

    getSpanTime(i: number): string {
        var date = this.calculateTime(i);
        const timePortion = (i % 4 ==0) ? 0 : 3;
        return date.toTimeString().split(" ")[0].substring(timePortion, 5)
    }
  
    onBookMeeting(i: number, $event: MouseEvent) {
        $event.preventDefault();
        $event.stopPropagation();
        let startTime = new Date(Date.now()
            + this.dayModifier * 24 * 60 * 60 * 1000).toISOString().slice(0, 10);
        let t = this.calculateTime(i);
        let meetingTime = `${startTime}T${t.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' }) + ':00'}`;        
        this.store.dispatch(availabilityActions.bookMeeting({ mails: this.users.map(u => u.mail), startTime: meetingTime, duration: 30 }));
       
        return false;
    }

}
