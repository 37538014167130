import { Component } from '@angular/core';

@Component({
  selector: 'dir-login-failed',
  templateUrl: './login-failed.component.html',
  styleUrls: ['./login-failed.component.scss']
})
export class LoginFailedComponent {

}
