<div class="d-flex flex-row">
    <div class="card flex-grow-1">
        <div class="card-body">
            <div class="card-title">
                <h3>City Browser</h3>
            </div>
            <div class="d-flex flex-row">
                <div class="col-4 nodes">
                    <div *ngIf="parents.length">
                        <a href="#" (click)="navigateUp($event)"><i class="bi bi-arrow-90deg-up fs-2x"></i>...</a>
                    </div>
                    <div *ngFor="let item of currentNode?.contents">
                        <div *ngIf="item.type === 'folder'" class="my-n2">
                            <a href="#" class="link-primary" (click)="navigate(item, $event)">
                                <i class="bi bi-folder-fill fs-2x"></i> {{item.name}}</a>
                        </div>
                        <div *ngIf="item.type === 'file'" class="my-n2">
                            <a href="#" class="link-primary" (click)="select(item, $event)">
                                <i class="bi bi-image-fill fs-2x"></i> {{item.name}}</a>
                        </div>
                    </div>
                </div>
                <div *ngIf="selected" class="col-8 d-flex flex-row justify-content-end">
                    <!-- <div>{{selected.name}}</div> -->
                    <div>
                        <img width="400px" height="300px"
                            src="https://storagecityimages.blob.core.windows.net/images/{{preview.path}}" />
                    </div>
                </div>
                <div *ngIf="!selected" class="col-8 d-flex flex-row justify-content-end">
                    <div class="fs-2 text-muted placeholder-city d-flex flex-column justify-content-center align-items-center">Select a
                        city to preview</div>
                </div>
            </div>
            <div class="d-flex flex-row justify-content-end mt-2">
                <button class="btn btn-sm btn-secondary me-2" (click)="selectImage.emit(null)">Cancel</button>
                <button class="btn btn-sm btn-primary" (click)="selectImage.emit(selected.name)">Choose image</button>
            </div>
        </div>
    </div>
</div>
