import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subject, takeUntil } from 'rxjs';
import { SubscriptionStatus } from '../../auth/auth.models';
import * as fromRoot from '../../reducers';
import { ChatHelper } from './chat-helper';
import * as uiActions from '../../ui/ui.actions';

@Component({
    selector: 'dir-txt',
    templateUrl: './text.component.html',
    styleUrls: ['./text.component.scss']
})
export class TextComponent implements OnInit, OnDestroy {
    private unsubscribe$: Subject<void> = new Subject();
    @Input() text: string;

    isBlurred = false;
    isTeams = false;
    subscriptionStatus: SubscriptionStatus = SubscriptionStatus.Subscribed;
    subscriptionStatus$: Observable<SubscriptionStatus>;
    chatHelper: ChatHelper;
    constructor(private store: Store) {}

    ngOnInit(): void {
        this.chatHelper = new ChatHelper(this.text, this.isTeams);
        this.subscriptionStatus$ = this.store.pipe(select(fromRoot.selectSubscriptionStatus));
        let isDemo$ = this.store.pipe(select(fromRoot.selectIsDemo));
        let isTeams$ = this.store.pipe(select(fromRoot.selectIsTeams));
        combineLatest([this.subscriptionStatus$, isDemo$, isTeams$])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(([status, isDemo, isTeams]) => {
                this.subscriptionStatus = status;
                this.isTeams = isTeams;
                this.chatHelper.isTeams = isTeams;
                this.isBlurred =
                    !isDemo && (status === SubscriptionStatus.Expired || status === SubscriptionStatus.Lapsed);
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    onComposeMail($event: MouseEvent): boolean {
        if (this.chatHelper.onComposeMail()) {
            $event.preventDefault();
            $event.stopPropagation();
            return false;
        }
        //Return true to allow the default click action
        return true;
    }

    onChat($event: MouseEvent): boolean {
        const url = this.chatHelper.onChat();
        if (url.length == 0) {
            $event.preventDefault();
            $event.stopPropagation();
            return false;
        } else {
            this.store.dispatch(uiActions.openDeepLink({ url }));
        }
        return true;
    }

    canChat(): boolean {
        return this.chatHelper.canChat();
    }

    isValidEmail(): boolean {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(this.text);
    }

    isValidURL(): boolean {
        const urlRegex = /^https:\/\/[^ "]+$/;
        return urlRegex.test(this.text);
    }

    fieldType(): string {
        if (!this.text) return '';
        if (this.isValidURL()) return 'url';
        if (this.isValidEmail()) return 'mail';
        return '';
    }
}
