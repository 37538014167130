<!-- <div *ngFor="let group of usersGroups">{{group.title}} -- {{group.users.length}}</div> -->
<div class="card card-flush mb-5" *ngIf="categorySearchResults?.length > 0">
    <div class="card-body">
        <div class="row">
            <div class="col-3" *ngFor="let categorySearchResult of categorySearchResults">
                <div
                    [@listAnimation]="expandedCategories.includes(categorySearchResult.categoryId) ? categorySearchResult.items.length : (categorySearchResult.items.length < 10 ? categorySearchResult.items.length : 10)">
                    <div *ngFor="let categoryItem of categorySearchResult.items|slice:0:expandedCategories.includes(categorySearchResult.categoryId)?100:10"
                        class="mb-1 category-search-result">
                        <a routerLink="/directory/{{categorySearchResult.slug}}/{{categoryItem.name}}">
                            <i class="bi bi-folder" *ngIf="!categorySearchResult.icon"></i>
                            <i class="bi" [ngClass]="'bi-' + categorySearchResult.icon"
                                *ngIf="categorySearchResult.icon"></i>
                            <span class="mx-1" [innerHtml]="categoryItem.name|highlightSearch:searchString"></span>
                            <span class="badge badge-secondary">{{categoryItem.people.length}}</span>
                        </a>
                    </div>
                </div>

                <div
                    *ngIf="categorySearchResult.items.length > 10 && !expandedCategories.includes(categorySearchResult.categoryId)">
                    <a (click)="expandCategory(categorySearchResult.categoryId)" href="#">More...</a>
                </div>
                <div
                    *ngIf="categorySearchResult.items.length > 10 && expandedCategories.includes(categorySearchResult.categoryId)">
                    <a (click)="collapseCategory(categorySearchResult.categoryId)" href="#">Less...</a>
                </div>
            </div>
        </div>
    </div>
</div>
<dir-toolbar [itemsCount]="totalCount" [pageSize]="pageSize" [sortField]="sortField" [sortOrder]="sortOrder"
    [isCalendarHidden]="isCalendarHidden" [groupByField]="groupBy" [userColumnSettings]="userColumnSettings"
    (pageSizeChange)="setPageSize.emit($event)" [viewMode]="viewMode" (viewModeChange)="setViewMode.emit($event)"
    (sortFieldChange)="setSortField.emit($event)" (sortOrderChange)="setSortOrder.emit($event)"
    (groupByChange)="setGroupBy.emit($event)">
</dir-toolbar>
<dir-selected-users></dir-selected-users>
<div class="d-flex flex-row">
    <div class="flex-grow-1 me-2">
        @if (filterLetter != null && filterLetter !== '' && users.length === 0) {
        No users found with last name starting with '{{filterLetter}}'. <a (click)="onClearFilterLetter($event)"
            href="#">Clear filter.</a>
        }
        @switch (viewMode) {
        @case (usersViewMode.Table) {
        <div *ngIf="viewMode === usersViewMode.Table">
            <dir-users-table *ngIf="usersGroups.length === 0" [users]="users" [dataSourceType]="dataSourceType"
                [userColumnSettings]="userColumnSettings" [favorites]="favorites">
            </dir-users-table>
            <div *ngFor="let usersGroup of usersGroups; last as last">
                <h2 class="ms-2"><span [innerHTML]="usersGroup.title"></span> - {{usersGroup.originalUsersCount}}</h2>
                <dir-users-table [users]="usersGroup.users" [dataSourceType]="dataSourceType"
                    [userColumnSettings]="userColumnSettings" [favorites]="favorites">
                </dir-users-table>
                <div *ngIf="!last" class="mt-4"></div>
            </div>
        </div>
        }
        @case (usersViewMode.Calendar) {
        <dir-calendar></dir-calendar>
        }
        @default {
        <!-- Cards or Faces -->
        <div *ngIf="viewMode === usersViewMode.Cards || viewMode === usersViewMode.Faces">
            <dir-users-cards
                *ngIf="(viewMode === usersViewMode.Cards || viewMode === usersViewMode.Faces) && users.length > 0 && usersGroups.length === 0"
                [users]="users" [dataSourceType]="dataSourceType" [userColumnSettings]="userColumnSettings.grid"
                [favorites]="favorites" [viewMode]="viewMode">
            </dir-users-cards>
            <div *ngFor="let usersGroup of usersGroups; last as last">
                <h2 class="ms-2"><span [innerHTML]="usersGroup.title"></span> - {{usersGroup.originalUsersCount}}</h2>
                <dir-users-cards [users]="usersGroup.users" [dataSourceType]="dataSourceType"
                    [userColumnSettings]="userColumnSettings.grid" [favorites]="favorites"></dir-users-cards>
                <div *ngIf="!last" class="mt-4"></div>
            </div>
        </div>
        }
        }

        <!--begin::Pagination-->
        <div *ngIf="viewMode !== usersViewMode.Calendar" class="d-flex flex-stack flex-wrap pt-10" [ngClass]="{'d-none': totalCount < 1}">
            <pagination [totalItems]="totalCount" [itemsPerPage]="pageSize" [customPreviousTemplate]="prevTemplate"
                [customNextTemplate]="nextTemplate" (pageChanged)="setPage($event)" [maxSize]="maxPages"
                [customFirstTemplate]="firstTemplate" [customLastTemplate]="lastTemplate"
                [boundaryLinks]="pageSize * (maxPages) < totalCount">
            </pagination>
            <ng-template #prevTemplate>
                <li class="page-item previous">
                    <i class="previous"></i>
                </li>
            </ng-template>
            <ng-template #nextTemplate>
                <li class="page-item next">
                    <i class="next"></i>
                </li>
            </ng-template>
            <ng-template #firstTemplate>
                <li class="page-item first">
                    First
                </li>
            </ng-template>
            <ng-template #lastTemplate>
                <li class="page-item last">
                    Last
                </li>
            </ng-template>
        </div>
        <!--end::Pagination-->
    </div>
    <dir-alphabetic-filter *ngIf="viewMode !== usersViewMode.Calendar"></dir-alphabetic-filter>
</div>
