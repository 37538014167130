import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { settingsFeature } from './settings.reducer';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(settingsFeature)],
    exports: [],
    declarations: [],
    providers: []
})
export class SettingsModule {}
