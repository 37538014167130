import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as integrityActions from './integrity.actions';
import * as settingsActions from '../settings/settings.actions';
import * as dataActions from '../data/data.actions';
import * as appActions from '../app.actions';
import { integrityFeature } from './integrity.reducer';
import { catchError, endWith, finalize, from, map, mergeMap, of, switchMap } from 'rxjs';
import { IntegrityService } from './services/integrity.service';
import * as fromRoot from '../reducers';
import { Store, select } from '@ngrx/store';
import { concatLatestFrom } from '@ngrx/operators';
import { DirectoryUserModification, UserAndModifications } from './integrity.models';
import { SettingsType } from '../settings/settings.models';

@Injectable()
export class IntegrityEffects {
    constructor(private actions$: Actions, private integrityService: IntegrityService, private store: Store) {}

    runReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(integrityActions.runReport),
            concatLatestFrom(() => [
                this.store.select(fromRoot.selectUsersRaw), //ButFiltered),
                this.store.select(fromRoot.selectUsersUnsorted)
            ]),
            switchMap(([_, users, modifiedUsers]) => {
                return this.integrityService.runReport(users, modifiedUsers).pipe(
                    switchMap((report) => {
                        if (!report) {
                            return of(integrityActions.runReportFailure({ error: 'Report is empty' }));
                        }
                        return this.integrityService.saveReportResult(report).pipe(
                            map(() => {
                                console.log('Report');
                                return integrityActions.runReportSuccess({ report });
                            }),
                            catchError((error) => {
                                console.log('Error');
                                return of(integrityActions.runReportFailure(error));
                            })
                        );
                    })
                );
            })
        )
    );

    loadReportResult$ = createEffect(() =>
        this.actions$.pipe(
            ofType(integrityActions.loadReportResult),
            switchMap(() => {
                return this.integrityService.getReportResult().pipe(
                    map((report) => integrityActions.loadReportResultSuccess({ report })),
                    catchError((error) => {
                        return of(integrityActions.loadReportResultFailure(error));
                    })
                );
            })
        )
    );

    loadReportConfigData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(integrityActions.loadReportConfigData),
            switchMap(() => {
                return this.integrityService.getReportConfigData().pipe(
                    map((reportConfigData) => integrityActions.loadReportConfigDataSuccess({ reportConfigData })),
                    catchError((error) => {
                        return of(integrityActions.loadReportConfigDataFailure(error));
                    })
                );
            })
        )
    );

    saveReportConfigData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(integrityActions.saveReportConfigData),
            switchMap((action) => {
                return this.integrityService.saveReportConfigData(action.reportConfigData).pipe(
                    map(() =>
                        integrityActions.saveReportConfigDataSuccess({ reportConfigData: action.reportConfigData })
                    ),
                    catchError((error) => {
                        return of(integrityActions.saveReportConfigDataFailure(error));
                    })
                );
            })
        )
    );

    saveReportConfigDataSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(integrityActions.saveReportConfigDataSuccess),
            map((action) => settingsActions.saveIntegrity({ integrity: action.reportConfigData }))
        )
    );

    saveUserModifications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(integrityActions.saveUserModifications),
            switchMap((action) => {
                return this.integrityService.saveUserModifications(action.modifications).pipe(
                    map((response) => integrityActions.saveUserModificationsSuccess({ response })),
                    catchError((error) => {
                        return of(integrityActions.saveUserModificationsFailure(error));
                    })
                );
            })
        )
    );

    saveUserModificationsSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(integrityActions.saveUserModificationsSuccess),
            switchMap((action) =>
                this.integrityService
                    .saveUserModificationsInDb(action.response[SettingsType.Modifications]?.value)
                    .pipe(map(() => settingsActions.saveUserModificationsSuccess({ response: action.response })))
            )
            //map((action) => settingsActions.saveUserModificationsSuccess({ response: action.response }))
        )
    );

    saveUserModificationsInSettingsSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dataActions.rebuildCategoriesFromDbSuccess, dataActions.rebuildCategoriesFromDbWithUsersSuccess),
            map(() => integrityActions.runReport())
        )
    );

    usersUpdate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(integrityActions.usersUpdate),
            switchMap((action) => {
                // save all modifications user by user and fire success or failure actions for each user
                // create observable that would save each user and emit action when all calls completed or failed
                // use integrityService like this: this.integrityService.updateUser(changes, id);
                const updates = action.usersAndModifications.map((userAndModifications: UserAndModifications) => {
                    const modifications = userAndModifications.modifications;
                    return this.integrityService.updateUser(modifications, userAndModifications.user.id).pipe(
                        map(() => integrityActions.userUpdateSuccess({ userAndModifications })),
                        catchError((error) => of(integrityActions.userUpdateFailure({ userAndModifications })))
                    );
                });

                return from(updates).pipe(
                    mergeMap((update) => update),
                    endWith(integrityActions.usersUpdateFinished())
                );
            })
        )
    );

    userUpdateFinished$ = createEffect(() =>
        this.actions$.pipe(
            ofType(integrityActions.usersUpdateFinished),
            concatLatestFrom(() => this.store.select(integrityFeature.selectIntegrityLogsPending)),
            mergeMap(([_, pendingLogs]) => {
                console.log('pendingLogs', pendingLogs);
                if (pendingLogs.length) {
                    return this.integrityService.postLogs(pendingLogs).pipe(
                        map(() => integrityActions.postIntegrityLogsSuccess()),
                        catchError((error) => of(integrityActions.postIntegrityLogsFailure({ error: error.error })))
                    );
                }
                return of(integrityActions.postIntegrityLogsSuccess());
            })
        )
    );

    postIntegrityLogsFailure$ = createEffect(() =>
        this.actions$.pipe(
            ofType(integrityActions.postIntegrityLogsFailure),
            map(() => appActions.toastFailure({ message: 'Failed to save log of changes' }))
        )
    );

    userUpdateSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(integrityActions.userUpdateSuccess),
            map(() => appActions.toastSuccess({ message: 'User updated' }))
        )
    );

    loadIntegrityLogs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(integrityActions.loadIntegrityLogs),
            switchMap(() => {
                return this.integrityService.getLogs().pipe(
                    map((logs) => integrityActions.loadIntegrityLogsSuccess({ logs })),
                    catchError((error) => {
                        return of(integrityActions.loadIntegrityLogsFailure({ error }));
                    })
                );
            })
        )
    );

    updateUserProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(integrityActions.updateUserProfile),
            concatLatestFrom(() => this.store.select(fromRoot.selectUser)),
            switchMap(([action, user]) => {
                return this.integrityService
                    .updateUserProfile(action.userId, action.payload, user.localAccountId === action.userId)
                    .pipe(
                        map(() => integrityActions.updateUserProfileSuccess()),
                        catchError((error) => {
                            return of(
                                integrityActions.updateUserProfileFailure({
                                    error: error.error.error
                                })
                            );
                        })
                    );
            })
        )
    );

    updateUserProfileSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(integrityActions.updateUserProfileSuccess),
            map(() => appActions.toastSuccess({ message: 'Profile updated' }))
        )
    );
}
