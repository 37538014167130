<!--begin::Root-->
<div class="d-flex flex-column flex-root">
    <dir-information-banner [isDemo]="(isDemo$|async)"
        (numberOfBannersShowing)="numberOfBannersShowing = $event"></dir-information-banner>
    <!--begin::Page-->
    <div class="app-page flex-column flex-column-fluid">
        <div class="background"
            *ngIf="!(activeCategoryItem$|async)?.pictureUrl && (activeCategoryItem$|async)?.patternUrl"
            [style.background-image]="'url(' + (activeCategoryItem$|async)?.patternUrl + ')'"
            [class]="(activeCategoryItem$|async)?.pictureClass"></div>

        <dir-category-image [size]="'B'" *ngIf="(activeCategoryItem$|async)?.pictureUrl"
            [imageId]="(activeCategoryItem$|async)?.pictureUrl"></dir-category-image>

        <div class="background-overlay" [class]="(activeCategoryItem$|async)?.pictureClass"
            *ngIf="(activeCategoryItem$|async)?.patternUrl || (activeCategoryItem$|async)?.pictureUrl"></div>
        <dir-header></dir-header>
        <!--begin::Wrapper-->
        <div class="flex-column flex-row-fluid" [class.app-wrapper]="showAsideComponent">
            <dir-aside *ngIf="showAsideComponent" [shiftDownPx]="numberOfBannersShowing * 19.5"></dir-aside>
            <div *ngIf="activeViewType === viewTypes.CategoryItem" class="container-fluid app-container">
                <div class="d-flex flex-row align-items-center">
                    <h2 class="mb-0">{{(activeCategoryItem$|async)?.name}}</h2>
                    <dir-admin-button classes="ms-2 btn btn-sm btn-primary"
                        (clickOutput)="editOffice($event);">Edit</dir-admin-button>
                    <button class="ms-2 btn btn-sm btn-success"
                        *ngIf="(editModeInSharePoint$|async) && (isOnSharePointStartingUrl$|async) !== true"
                        (click)="pinWebPart()">
                        <i class="ki-duotone ki-pin">
                            <span class="path1"></span>
                            <span class="path2"></span>
                        </i>
                        Pin
                    </button>
                    <button class="ms-2 btn btn-sm btn-light-success"
                        *ngIf="(editModeInSharePoint$|async) && (isOnSharePointStartingUrl$|async)"
                        (click)="unpinWebPart()">
                        <i class="ki-duotone ki-pin">
                            <span class="path1"></span>
                            <span class="path2"></span>
                        </i>
                        Unpin
                    </button>
                </div>
            </div>
            <div *ngIf="isDirectoryAvailable$|async" class="app-main flex-column flex-row-fluid">
                <div class="d-flex flex-column flex-column-fluid">
                    <!--begin::Content-->
                    <div class="app-content flex-column-fluid">
                        <!--begin::Container-->
                        <div class="app-container container-fluid">
                            <!--begin::Alert-->
                            <div *ngIf="hasLoadDataFromDbFailed$|async"
                                class="alert alert-danger d-flex align-items-center p-5">
                                <!--begin::Icon-->
                                <i class="ki-duotone ki-disconnect fs-2hx text-danger me-4"><span
                                        class="path1"></span><span class="path2"></span></i>
                                <!--end::Icon-->
                                <!--begin::Wrapper-->
                                <div class="d-flex flex-column">
                                    <!--begin::Title-->
                                    <h4 class="mb-1 text-dark">Could not load data</h4>
                                    <!--end::Title-->

                                    <!--begin::Content-->
                                    <span>TeamDirectory failed to load your directory data, try reloading the page or
                                        checking your <a routerLink="/settings/dataSources">data source
                                            settings</a>.</span>
                                    <!--end::Content-->
                                </div>
                                <!--end::Wrapper-->
                            </div>
                            <!--end::Alert-->
                            <div class="row mb-2 progress-container" *ngIf="(progress$|async) !== 100"
                                [@slideUpDown]="(progress$|async) !== 100">
                                <div class="col">
                                    <div class="m-2">
                                        Loading users: {{progress$|async|number:'1.0-0'}}%
                                        <progressbar [value]="progress$|async" [striped]="true">
                                            {{progress$|async|number:'1.0-0'}}%
                                        </progressbar>
                                    </div>
                                </div>
                            </div>
                            <dir-category-items *ngIf="activeViewType === viewTypes.Category"
                                [category]="activeCategory$|async" [categoryItems]="activeCategoryItemsSorted$|async"
                                [viewMode]="(categoryItemsViewModes$|async)[(activeCategory$|async)?.fieldName] ?? 0"
                                [sortField]="(categoryItemsSortFields$|async)[(activeCategory$|async)?.fieldName]"
                                [sortOrder]="(categoryItemsSortOrders$|async)[(activeCategory$|async)?.fieldName]"
                                (setViewMode)="onSetCategoryItemsViewMode($event)"
                                (setSortField)="onSetCategorySortField($event)"
                                (setSortOrder)="onSetCategorySortOrder($event)"></dir-category-items>
                            <dir-users *ngIf="(displayedUsers$|async) && activeViewType !== viewTypes.Category"
                                [users]="displayedUsers$|async" [usersGroups]="displayedUsersGroups$|async"
                                [totalCount]="displayedCount$|async" [dataSourceType]="dataSourceType$|async"
                                [pageSize]="usersPageSize$|async" [viewMode]="usersViewMode$|async"
                                [currentPage]="usersPageNumber$|async" [searchString]="searchString$|async"
                                [sortField]="sortField$|async" [sortOrder]="sortOrder$|async" [groupBy]="groupBy$|async"
                                [isCalendarHidden]="isCalendarHidden$|async"
                                [filterLetter]="filterLetter$|async" (clearFilterLetter)="onClearFilterLetter()"
                                [categorySearchResults]="(searchResults$|async)?.categorySearchResults"
                                (setViewMode)="onSetUsersViewMode($event)" (setPageSize)="onSetUsersPageSize($event)"
                                (setPageNumber)="onSetUsersPageNumber($event)" (setSortField)="onSetSortField($event)"
                                (setSortOrder)="onSetSortOrder($event)" (setGroupBy)="onSetGroupBy($event)"
                                [userColumnSettings]="userColumnSettings$|async" [favorites]="favorites$|async">
                            </dir-users>
                        </div>
                        <!--end::Container-->
                    </div>
                    <!--end::Content-->
                </div>
                @if (isFromToc$|async) {
                <div class="mt-2"></div>
                } @else {
                <dir-footer></dir-footer>
                }
            </div>
            <div *ngIf="(isDirectoryAvailable$|async) === false" class="app-main flex-column flex-row-fluid">
                <dir-consent [isInline]="true"></dir-consent>
            </div>
        </div>
        <!--end::Wrapper-->
    </div>
    <!--end::Page-->
</div>
<!--end::Root-->
