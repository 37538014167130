import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppDB } from './db.service';

import { CategoryImage } from '../data/data.models';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ImageService {
    //observable that emits image id when image should be refreshed
    refreshedImageId$ = new Subject<string>();
    constructor(private http: HttpClient, private store: Store, private db: AppDB) {}

    refreshImage(imageId: string) {
        this.refreshedImageId$.next(imageId);
    }

    saveImage(image: CategoryImage): Observable<string> {
        return this.http.post<string>('/api/image', image);
    }

    loadImageFromServer(imageId: string): Observable<CategoryImage> {
        return this.http.get<CategoryImage>(`/api/image/${imageId}`);
    }

    loadAllImages(time: number): Observable<CategoryImage[]> {
        return this.http.get<CategoryImage[]>(`/api/image/all/${time}`);
    }
}
