import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, mergeMap, of, switchMap, tap, withLatestFrom } from 'rxjs';
import * as dataActions from './data.actions';
import * as settingsActions from '../settings/settings.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppDB } from '../services/db.service';
import { CategoriesService } from '../services/categories.service';
import { MsalErrors } from '../auth/auth.models';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../reducers';
import * as appActions from '../app.actions';
import { uiFeature } from '../ui/ui.reducer';
import { DataServiceProvider } from '../services/data-service.provider';
import { RefreshTimestampKey } from './data.models';
import { DataSourceType } from '../services/data-service.factory';
import { settingsFeature } from '../settings/settings.reducer';

@Injectable()
export class DataEffects {
    private previousUrl: string = null;
    constructor(
        private actions$: Actions,
        private dataServiceProvider: DataServiceProvider,
        private categoriesService: CategoriesService,
        private router: Router,
        private route: ActivatedRoute,
        private location: Location,
        private db: AppDB,
        private store: Store
    ) {}

    loadUsers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dataActions.loadUsers),
            switchMap(() =>
                this.dataServiceProvider
                    .getDataService()
                    .getData()
                    .pipe(map((users) => dataActions.loadUsersSuccess(users)))
            )
        )
    );

    reloadUsers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dataActions.reloadUsers, dataActions.reloadUsersTopbar, dataActions.reloadUsersAfterConsent),
            switchMap(() =>
                this.dataServiceProvider
                    .getDataService()
                    .reloadData()
                    .pipe(
                        map((users) => dataActions.reloadUsersSuccess(users)),
                        catchError((error) => {
                            if (error.error?.error === MsalErrors.consentRequired) {
                                return of(dataActions.reloadUsersAfterConsent());
                                //return of(dataActions.reloadUsersFailure({ error }));
                            }
                            return of(dataActions.reloadUsersFailure({ error: error.error }));
                        })
                    )
            )
        )
    );

    reloadUsersSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dataActions.reloadUsersSuccess),
            map(() => dataActions.rebuildCategoriesFromDbWithUsers())
        )
    );

    updateSearchString$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(dataActions.updateSearchString),
                tap(({ searchString }) => {
                    console.log(this.route.snapshot, this.router.url);
                    if (searchString && searchString.trim().length > 0 && !this.previousUrl) {
                        this.previousUrl = this.router.url;
                    }
                }),
                withLatestFrom(this.store.pipe(select(uiFeature.selectIsAppEntryPointSearchPage))),
                map(([{ searchString }, isAppEntryPointSearchPage]) => {
                    if (searchString == null || searchString.length === 0) {
                        console.log('navigating BACK');
                        if (isAppEntryPointSearchPage) {
                            console.log('navigating to /directory');
                            this.router.navigate(['/directory']);
                            return;
                        }
                        if (this.previousUrl) {
                            this.router.navigate([this.previousUrl]);
                            this.previousUrl = null;
                            return;
                        }
                        this.location.back();
                        return;
                    }
                    if (searchString && searchString.trim().length > 0) {
                        this.router.navigate(['/directory/search', searchString.trim()], {
                            replaceUrl: this.router.url?.startsWith('/directory/search')
                        });
                    }
                })
            ),
        { dispatch: false }
    );

    loadDataFromDb$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dataActions.loadDataFromDb, dataActions.loadDataFromDbSettings, dataActions.loadDataFromDbDirectory),
            switchMap(() =>
                this.categoriesService.loadDataFromDb().pipe(
                    map((dataFromDb) => {
                        return dataActions.loadDataFromDbSuccess({
                            dataFromDb
                        });
                    }),
                    catchError((error) => {
                        console.log(error);
                        if (
                            error?.errorCode === MsalErrors.userCancelled ||
                            error?.errorCode === MsalErrors.consentRequired ||
                            error?.errorCode === MsalErrors.accessDenied ||
                            error?.errorCode === MsalErrors.popupBlocked
                        ) {
                            return of(
                                dataActions.loadDataFromDbFailure({
                                    error: { errorCode: error.errorCode, errorMessage: error.errorMessage }
                                })
                            );
                        }
                        return of(
                            dataActions.loadDataFromDbFailure({
                                error: { errorCode: error.errorCode, errorMessage: error.errorMessage }
                            })
                        );
                    })
                )
            )
        )
    );

    loadDataFromDbFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(dataActions.loadDataFromDbFailure),
                tap(({ error }) => {
                    if (error?.errorCode === MsalErrors.userCancelled) {
                        console.warn('user cancelled');
                        this.router.navigate(['/consent']);
                        return;
                    }
                    if (error?.errorCode == MsalErrors.popupBlocked) {
                        console.warn('pop-up window blocked consent.');
                        this.router.navigate(['/consent']);
                        return;
                    }
                    if (error?.errorCode === MsalErrors.consentRequired) {
                        console.warn('consent required');
                        this.router.navigate(['/consent']);
                        return;
                    }
                    if (error?.errorCode === MsalErrors.accessDenied) {
                        console.warn('access denied');
                        this.router.navigate(['/consent']);
                        return;
                    }
                    console.error('unknown error:', error);
                })
            ),
        { dispatch: false }
    );

    loadDataFromDbFailureToast$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dataActions.loadDataFromDbFailure),
            filter(
                ({ error }) =>
                    error?.errorCode !== MsalErrors.userCancelled &&
                    error?.errorCode !== MsalErrors.consentRequired &&
                    error?.errorCode !== MsalErrors.accessDenied &&
                    error?.errorCode !== MsalErrors.popupBlocked
            ),
            map(() => appActions.toastFailure({ message: 'Error loading data from DB' }))
        )
    );

    loadDataFromDbSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dataActions.loadDataFromDbSuccess),
            map(({ dataFromDb }) => {
                const { users } = dataFromDb;
                if (users.length === 0) {
                    console.log('no users, reloading users');
                    return dataActions.loadUsers();
                }
                if (dataFromDb.isRefreshNeeded) {
                    return dataActions.rebuildCategoriesFromDbWithUsers();
                }
                if (dataFromDb.categories?.length === 0 && dataFromDb.savedCategories?.length > 0) {
                    console.log('no categories, but saved categories, using saved categories');
                    //this should be decided in a service, while loading data from db
                    return dataActions.rebuildCategoriesFromDbWithUsers();
                }
                return dataActions.noOp();
            })
        )
    );

    //this isn't a good effect, needs refactoring
    saveCategoryItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dataActions.saveCategoryItem),
            mergeMap((action) =>
                this.categoriesService.saveCategoryItem(action.categoryItem).pipe(
                    map((savedCategoryItems) => {
                        console.log('savedCategoryItems', savedCategoryItems);
                        return settingsActions.saveCategoryItemsInSettings({ savedCategoryItems });
                    })
                )
            )
        )
    );

    rebuldCategoriesFromDb$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dataActions.rebuildCategoriesFromDb),
            mergeMap(() =>
                this.categoriesService
                    .rebuildCategoriesFromDb()
                    .pipe(map((dataFromDb) => dataActions.rebuildCategoriesFromDbSuccess({ dataFromDb })))
            )
        )
    );

    rebuldCategoriesFromDbWithUsers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dataActions.rebuildCategoriesFromDbWithUsers),
            mergeMap(() =>
                this.categoriesService
                    .rebuildCategoriesFromDbWithUsers()
                    .pipe(map((dataFromDb) => dataActions.rebuildCategoriesFromDbWithUsersSuccess({ dataFromDb })))
            )
        )
    );

    deleteDb$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dataActions.deleteDb),
            mergeMap(() =>
                this.db
                    .deleteDatabase()
                    .pipe(mergeMap(() => this.db.openDatabase().pipe(map(() => dataActions.deleteDbSuccess()))))
            )
        )
    );

    deleteDbSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dataActions.deleteDbSuccess),
            map(() => dataActions.reloadUsers())
        )
    );

    hasRefreshedData$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(dataActions.hasRefreshedData),
                tap(() => {
                    localStorage.setItem(RefreshTimestampKey, new Date().toISOString());
                })
            ),
        { dispatch: false }
    );

    getIndividualDataAfterRefresh$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dataActions.hasRefreshedData),
            withLatestFrom(
                this.store.pipe(select(settingsFeature.selectIsCalendarHidden)),
                this.store.pipe(select(fromRoot.selectDataSourceType))
            ),
            mergeMap(([_, isCalendarHidden, dataSourceType]) => {
                if (isCalendarHidden || dataSourceType !== DataSourceType.Graph) {
                    return of(dataActions.noOp());
                }
                return this.db.getUsers().pipe(
                    mergeMap((users) =>
                        this.dataServiceProvider
                            .getDataService()
                            .getIndividualUsersData(users)
                            .pipe(
                                map((data) => {
                                    if (data == null || data.length === 0) {
                                        return dataActions.loadIndividualUsersNoResults();
                                    }
                                    return dataActions.loadIndividualUsersSuccess({
                                        users: data,
                                        dataSourceType: this.dataServiceProvider.getDataService().getDataSourceType()
                                    });
                                })
                            )
                    )
                );
            })
        )
    );

    loadIndividualUsersSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(dataActions.loadIndividualUsersSuccess),
            switchMap(({ users }) =>
                this.db.replaceUsers(users).pipe(map(() => dataActions.rebuildCategoriesFromDbWithUsers()))
            )
        )
    );
}
