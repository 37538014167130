
<div *ngIf="userAvailability && !userAvailability.NoCalendar" class="calendar">

    <div class="d-flex justify-content-between align-items-center">
        <button (click)="onChangeDay(-1)" class="btn btn-link" [disabled]="dayModifier<=0">
            <i class="square bi bi-chevron-left px-1"></i>
        </button>
        <div class="calendar-description text-center flex-grow-1">{{getAvailabilityDescription()}}</div>
        <button (click)="onChangeDay(1)" class="btn btn-link">
            <i class="square bi bi-chevron-right px-1"></i>
        </button>
    </div>
    <div class="calendar-description">{{getAvailabilityPeriodDescription()}}</div>
    <div *ngIf="userAvailability && !userAvailability.NoCalendar" class="calendar-wrapper">
        <div class="calendar-block">
            <div *ngFor="let p of timePeriods; let j = index" class="calendar-line">
                <span *ngFor="let t of p; let i = index" [title]="getTooltip(t,j,i)" [ngClass]="{
                        'calendar-free': t === '0',
                        'calendar-tentative': t === '1',
                        'calendar-busy': t === '2',
                        'calendar-ooo': t === '3',
                        'calendar-elsewhere': t === '4',
                        'calendar-clash': t === '8',
                        'calendar-lg' : size === 'lg' && i * 15!==60,
                        'calendar-lg-hour' : size === 'lg' && (i * 15===60 || i ===0),
                        'calendar-sm': size !== 'lg'
                        }" (click)="onBookMeeting($event,t,j,i)">
                    <span>{{getSpanTime(j,i)}}</span>
                </span>
            </div>
        </div>
    </div>

    <div class="d-flex w-100 mt-3 justify-content-between compare-with-me calendar-description">
        <span class="" (click)="onToggleShowTimeLabels()">
            <span class="align-self-start mr-2">show time labels: </span>
            <span>
                <svg *ngIf="showTimeLabels" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                     fill="currentColor" class="bi bi-toggle-on" viewBox="0 0 16 16">
                    <path d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10H5zm6 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8z" />
                </svg>
                <svg *ngIf="!showTimeLabels" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                     fill="currentColor" class="bi bi-toggle-off" viewBox="0 0 16 16">
                    <path d="M11 4a4 4 0 0 1 0 8H8a4.992 4.992 0 0 0 2-4 4.992 4.992 0 0 0-2-4h3zm-6 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM0 8a5 5 0 0 0 5 5h6a5 5 0 0 0 0-10H5a5 5 0 0 0-5 5z" />
                </svg>
            </span>
        </span>

        <span class="ml-5 align-self-end" (click)="onToggleCompareWithMe()">
            <span>Compare with my schedule: </span>
            <span class="ml-2">
                <svg *ngIf="compareWithMe" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-toggle-on" viewBox="0 0 16 16">
                    <path d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10H5zm6 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8z" />
                </svg>
                <svg *ngIf="!compareWithMe" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                     fill="currentColor" class="bi bi-toggle-off" viewBox="0 0 16 16">
                    <path d="M11 4a4 4 0 0 1 0 8H8a4.992 4.992 0 0 0 2-4 4.992 4.992 0 0 0-2-4h3zm-6 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM0 8a5 5 0 0 0 5 5h6a5 5 0 0 0 0-10H5a5 5 0 0 0-5 5z" />
                </svg>
            </span>
        </span>
        <span class="ml-5 align-self-end" (click)="onToggle12HourTime()">
            <span>12/24hr: </span>
            <span class="ml-2">
                <svg *ngIf="show12HourTime" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-toggle-on" viewBox="0 0 16 16">
                    <path d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10H5zm6 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8z" />
                </svg>
                <svg *ngIf="!show12HourTime" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                     fill="currentColor" class="bi bi-toggle-off" viewBox="0 0 16 16">
                    <path d="M11 4a4 4 0 0 1 0 8H8a4.992 4.992 0 0 0 2-4 4.992 4.992 0 0 0-2-4h3zm-6 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM0 8a5 5 0 0 0 5 5h6a5 5 0 0 0 0-10H5a5 5 0 0 0-5 5z" />
                </svg>
            </span>
        </span>
    </div>
    <span style="width:5%"></span>
    <span class="calendar-break mt-1"></span>
    <span style="width:5%"></span>
    <div *ngIf="compareWithMe" class="w-100 mt-3">
        <div class="row">
            <div class="col-1">
                <span [title]="getPeriodText('0')" class="calendar-free calendar-key calendar-sm"></span>
            </div>
            <div class="col-5">
                <label class="mr-2" style="font-size:0.8rem">{{getPeriodText('0')}}</label>
            </div>

            <div class="col-1">
                <span [title]="getPeriodText('8')" class="calendar-clash calendar-key calendar-sm"></span>
            </div>
            <div class="col-5">
                <label class="mr-2" style="font-size:0.8rem">{{getPeriodText('8')}}</label>
            </div>
        </div>
    </div>

    <div *ngIf="!compareWithMe" class="w-100 mt-3">
        <div class="row">
            <div class="col-1">
                <span [title]="getPeriodText('0')" class="calendar-free calendar-key calendar-sm"></span>
            </div>
            <div class="col-3">
                <label class="mr-2" style="font-size:0.8rem">{{getPeriodText('0')}}</label>
            </div>

            <div class="col-1">
                <span [title]="getPeriodText('1')" class="calendar-tentative calendar-key calendar-sm"></span>
            </div>
            <div class="col-3">
                <label class="mr-2" style="font-size:0.8rem">{{getPeriodText('1')}}</label>
            </div>

            <div class="col-1">
                <span [title]="getPeriodText('2')" class="calendar-busy calendar-key calendar-sm"></span>
            </div>
            <div class="col-3">
                <label class="mr-2" style="font-size:0.8rem">{{getPeriodText('2')}}</label>
            </div>
        </div>

        <div class="row">
            <div class="col-1">
                <span [title]="getPeriodText('3')" class="calendar-ooo calendar-key calendar-sm"></span>
            </div>
            <div class="col-3">
                <label class="mr-2" style="font-size:0.8rem">{{getPeriodText('3')}}</label>
            </div>
            <div class="col-1">
                <span [title]="getPeriodText('4')" class="calendar-elsewhere calendar-key calendar-sm"></span>
            </div>
            <div class="col-7">
                <label class="mr-2" style="font-size:0.8rem">{{getPeriodText('4')}}</label>
            </div>
        </div>
    </div>
</div>

<div *ngIf="userAvailability && userAvailability.NoCalendar"
     class="d-flex justify-content-center align-items-center bg-secondary no-availability-available">
    <p>Calendar Not Available</p>
</div>

<div *ngIf="!featureConsented && !calendarAlertDismissed"
     class="alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row p-2 mb-4">
    <i class="ki-duotone ki-calendar fs-2hx text-primary me-4 mb-5 mb-sm-0">
        <span class="path1"></span><span class="path2"></span><span class="path3"></span>
    </i>
    <div class="d-flex flex-column pe-0 pe-sm-10">
        <h4 class="fw-semibold">Calendar</h4>
        <p>
            The calendar feature is not available, it can be enabled in <a routerLink="settings/consent"
                                                                           (click)="dismiss.emit()">settings</a>.
        </p>
    </div>
    <button type="button" (click)="onDismissCalendarAlert()"
            class="position-absolute position-sm-relative my-n2 top-0 end-0 btn btn-icon ms-sm-auto">
        <i class="ki-duotone ki-cross fs-1 text-primary"><span class="path1"></span><span class="path2"></span></i>
    </button>
</div>
